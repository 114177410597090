import React from "react"
import {
    HorizontalStack,
    type StyleProps,
} from "@mallardbay/lib-react-components"

import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"

export default function HorizontalStackSpaceBetween({
    style,
    children,
}: PropsWithChildrenAndStyle) {
    const styles = useStyles()

    return (
        <HorizontalStack style={{ ...styles.root, ...style }}>
            {children}
        </HorizontalStack>
    )
}

function useStyles() {
    const root: StyleProps = {
        justifyContent: "space-between",
    }

    return { root }
}
