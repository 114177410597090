import React from "react"
import { Box, useColors } from "@mallardbay/lib-react-components"

import COPY_CONSTANTS from "~config/copy-constants"
import UnreadMessageCountBadge from "~components/shared/courier-inbox/unread-message-count-badge"
import AccountNavigatorItem from "~components/pages/account/account-navigator-item"

export default function MobileAccountNavigatorCourierButton() {
    const styles = useStyles()

    return (
        <Box style={styles.root}>
            <AccountNavigatorItem
                title={COPY_CONSTANTS.NOTIFICATIONS}
                Icon={UnreadMessageCountBadge}
            />
        </Box>
    )
}

function useStyles() {
    const colors = useColors()

    return {
        root: {
            borderBottom: `1px solid ${colors.borderOpaque} !important`,
        },
    }
}
