import React, { type PropsWithChildren } from "react"
import {
    componentStyles,
    HorizontalStackSpaceBetween,
} from "@mallardbay/lib-react-components"

import ReviewAggregation from "~components/shared/price-and-rating/review-aggregation"
import type { ReviewAggregationFieldsFragment } from "~graphql/generated/graphql"
import { TEST_IDS } from "~config/test-ids"

export default function ContainerWithRating({
    reviewAggregation,
    noPadding,
    children,
}: Props) {
    const styles = useStyles({ noPadding })

    // If there are no children and no reviewAggregation, return null
    // So we don't take space
    if (!children && !reviewAggregation) {
        return null
    }

    return (
        <HorizontalStackSpaceBetween
            style={styles.root}
            testId={TEST_IDS.CONTAINER_WITH_RATING}
        >
            <div>{children}</div>
            <ReviewAggregation reviewAggregation={reviewAggregation} />
        </HorizontalStackSpaceBetween>
    )
}

export interface Props extends PropsWithChildren {
    readonly reviewAggregation:
        | ReviewAggregationFieldsFragment
        | null
        | undefined
    readonly noPadding?: boolean
}

function useStyles({ noPadding }: { noPadding?: boolean }) {
    const paddingTop = noPadding ? 0 : 3

    return componentStyles({
        root: {
            fontWeight: 300,
            paddingTop,
        },
    })
}
