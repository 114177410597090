import { CustomHeaders } from "~config/constants"

import { getUtmQueryParams } from "./url-param-helpers"
import { removeUndefinedProps } from "./object-helpers"

export function getUtmHeaders() {
    const utmParams = getUtmQueryParams()

    const headers = {
        [CustomHeaders.UTM_CAMPAIGN]: utmParams.utmCampaign,
        [CustomHeaders.UTM_CONTENT]: utmParams.utmContent,
        [CustomHeaders.UTM_MEDIUM]: utmParams.utmMedium,
        [CustomHeaders.UTM_SOURCE]: utmParams.utmSource,
        [CustomHeaders.UTM_TERM]: utmParams.utmTerm,
    }

    return removeUndefinedProps(headers)
}
