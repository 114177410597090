import { Spinner as SpinnerOg } from "@chakra-ui/react"
import {
    Box,
    componentStyles,
    useTheme,
} from "@mallardbay/lib-react-components"
import React from "react"

import { TEST_IDS } from "~config/test-ids"

interface Props {
    readonly size?: string
    readonly noPadding?: boolean
    readonly shouldInvertColor?: boolean
}

export default function Spinner({
    size = "lg",
    noPadding,
    shouldInvertColor,
}: Props) {
    const styles = useStyles({ noPadding })
    const { colors } = useTheme()
    const spinnerColor = shouldInvertColor
        ? colors.contentInversePrimary
        : colors.contentPrimary

    return (
        <Box style={styles.root}>
            <SpinnerOg
                data-testid={TEST_IDS.SPINNER}
                size={size}
                color={spinnerColor}
            />
        </Box>
    )
}

function useStyles({ noPadding }: { noPadding?: boolean }) {
    return componentStyles({
        root: {
            alignSelf: "center",
            textAlign: "center",
            paddingTop: noPadding ? "0" : "96px",
        },
    })
}
