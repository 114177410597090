import React from "react"

import type { Props as CoreProps } from "~components/shared/collections/outfitters-collection"
import OutfittersCollection from "~components/shared/collections/outfitters-collection"
import { usePublicOutfitters } from "~utils/use-outfitters"

type OutfitterQueryType =
    (typeof OUTFITTER_QUERY_TYPES)[keyof typeof OUTFITTER_QUERY_TYPES]

export const OUTFITTER_QUERY_TYPES = {
    ID_LIST: "ID_LIST",
} as const

export interface Props extends CoreProps {
    readonly idList: string[]
    readonly queryType: OutfitterQueryType
}

export default function OutfittersCollectionQueryable(props: Props) {
    const { idList, ...genericProps } = props
    const { outfitters, isLoading } = useQueryOutfitters(props)

    return (
        <OutfittersCollection
            {...genericProps}
            outfitters={outfitters}
            isLoading={isLoading}
        />
    )
}

function useQueryOutfitters({ queryType, idList }: Props) {
    const { loading, error, data } = usePublicOutfitters({
        idList,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- TODO fix eslint
        skip: queryType !== OUTFITTER_QUERY_TYPES.ID_LIST,
    })

    const outfitters = data?.publicOutfitters || []

    return {
        isLoading: loading,
        error,
        outfitters,
        data,
    }
}
