import React from "react"
import {
    FormControl as FormControlOg,
    FormLabel,
    FormErrorMessage,
} from "@chakra-ui/react"
import {
    componentStyles,
    TextSecondaryExtraSmall,
} from "@mallardbay/lib-react-components"

import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"

interface Props extends PropsWithChildrenAndStyle {
    readonly label?: string
    readonly caption?: string
    readonly errorMessage?: string
}

export default function FormControl({
    style,
    label,
    caption,
    errorMessage,
    children,
}: Props) {
    const styles = useStyleProps()

    return (
        <FormControlOg
            sx={{ ...styles.root, ...style }}
            isInvalid={Boolean(errorMessage)}
        >
            {label && <FormLabel>{label}</FormLabel>}
            {children}
            {caption && (
                <TextSecondaryExtraSmall style={styles.caption}>
                    {caption}
                </TextSecondaryExtraSmall>
            )}
            <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControlOg>
    )
}

function useStyleProps() {
    return componentStyles({
        root: {
            marginBottom: "10px",
        },
        caption: {
            paddingTop: "8px",
        },
    })
}
