import { isServerSide } from "./helpers"

export function getViewportDimensions() {
    if (isServerSide()) {
        return { width: undefined, height: undefined }
    }

    return {
        width: window.innerWidth,
        height: window.innerHeight,
    }
}
