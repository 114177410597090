import React, {
    createContext,
    useContext,
    useState,
    useMemo,
    type PropsWithChildren,
} from "react"

interface GlobalNavigationContextType {
    isHeaderHidden: boolean
    setIsHeaderHidden: (isHidden: boolean) => void
    isFooterHidden: boolean
    setIsFooterHidden: (isHidden: boolean) => void
    isMobileTabBarHidden: boolean
    setGlobalNavigation: ({
        isHeaderHidden,
        isFooterHidden,
    }: Pick<
        GlobalNavigationContextType,
        "isHeaderHidden" | "isFooterHidden"
    >) => void
}

const GlobalNavigationContext = createContext<GlobalNavigationContextType>({
    isHeaderHidden: false,
    setIsHeaderHidden: () => {},
    isFooterHidden: false,
    isMobileTabBarHidden: false,
    setIsFooterHidden: () => {},
    setGlobalNavigation: () => {},
})

export function useGlobalNavigation() {
    return useContext(GlobalNavigationContext)
}

export function GlobalNavigationProvider({
    children,
}: Readonly<PropsWithChildren>) {
    const [isHeaderHidden, setIsHeaderHidden] = useState(false)
    const [isFooterHidden, setIsFooterHidden] = useState(false)

    const value = useMemo(
        () => ({
            setGlobalNavigation: ({
                isHeaderHidden: isHeaderHiddenLocal,
                isFooterHidden: isFooterHiddenLocal,
            }: Pick<
                GlobalNavigationContextType,
                "isHeaderHidden" | "isFooterHidden"
            >) => {
                setIsHeaderHidden(isHeaderHiddenLocal)
                setIsFooterHidden(isFooterHiddenLocal)
            },
            isHeaderHidden,
            setIsHeaderHidden,
            isFooterHidden,
            // Currently, we always wanna hide the mobile tab bar
            // if the footer is hidden. Setup for future use cases
            isMobileTabBarHidden: isFooterHidden,
            setIsFooterHidden,
        }),
        [isHeaderHidden, setIsHeaderHidden, isFooterHidden, setIsFooterHidden]
    )

    return (
        <GlobalNavigationContext.Provider value={value}>
            {children}
        </GlobalNavigationContext.Provider>
    )
}
