import React from "react"
import { componentStyles } from "@mallardbay/lib-react-components"
import { VStack } from "@chakra-ui/react"

import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"

interface VerticalStackProps extends PropsWithChildrenAndStyle {
    // eslint-disable-next-line react/no-unused-prop-types
    readonly testId?: string
}

export function VerticalStack({ style, children }: VerticalStackProps) {
    const styles = useStyles()

    return <VStack sx={{ ...styles.root, ...style }}>{children}</VStack>
}

export function VerticalStackStretch({
    style,
    children,
    testId,
}: VerticalStackProps) {
    const styles = useStyles()

    return (
        <VStack sx={{ ...styles.stretch, ...style }} data-testid={testId}>
            {children}
        </VStack>
    )
}

function useStyles() {
    return componentStyles({
        root: {
            gap: 4,
            alignItems: "flex-start",
        },
        stretch: {
            gap: 4,
            alignItems: "stretch",
        },
    })
}
