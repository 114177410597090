import React from "react"

import Skeleton from "~components/shared/todo-lib-react-components/skeleton"
import { VerticalStack } from "~components/shared/todo-lib-react-components/vertical-stack"
import { SUGGESTION_IMG_HEIGHT } from "~config/constants"

import OptionSkeletonRow from "./option-skeleton-row"

export default function OptionsSkeletonGroup() {
    return (
        <VerticalStack>
            <Skeleton width="300px" height={SUGGESTION_IMG_HEIGHT} />
            <OptionSkeletonRow />
            <OptionSkeletonRow />
            <OptionSkeletonRow />
            <OptionSkeletonRow />
            <OptionSkeletonRow />
        </VerticalStack>
    )
}
