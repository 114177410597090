import React from "react"
import type { IconType } from "react-icons"
import type { StyleProps } from "@mallardbay/lib-react-components"
import { Button, HorizontalStack } from "@mallardbay/lib-react-components"

import { Text } from "~components/shared/todo-lib-react-components/text"
import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"

// TODO use this style of toast for all toasts
export default function ToastWithActionContent({
    message,
    Icon,
    iconColor,
    actionLabel,
    onActionClick,
}: {
    readonly Icon: IconType
    readonly message: string
    readonly iconColor?: string
    readonly actionLabel: string
    readonly onActionClick: () => void
}) {
    const styles = useStyles()

    return (
        <HorizontalStack style={styles.root}>
            <Icon color={iconColor} />
            <Text>{message}</Text>
            <Button onClick={onActionClick}>{actionLabel}</Button>
        </HorizontalStack>
    )
}

function useStyles() {
    const colors = useColors()

    const root: StyleProps = {
        border: `1px solid ${colors.borderColor}`,
        paddingX: 4,
        paddingY: 2,
        borderRadius: "xl",
    }

    return { root }
}
