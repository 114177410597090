import React from "react"

import { SkeletonCircle } from "~components/shared/todo-lib-react-components/skeleton"
import type { Props } from "~components/shared/todo-lib-react-components/avatar"
import Avatar from "~components/shared/todo-lib-react-components/avatar"
import { useUser } from "~config/user-provider"
import { getFullName } from "~utils/helpers"

export default function CurrentUserAvatar({ size }: Props) {
    const { user, isFetching, fetchError } = useUser()

    if (fetchError) return null
    if (isFetching) {
        return <SkeletonCircle />
    }

    return (
        <Avatar
            size={size}
            src={user?.avatar_url ?? ""}
            name={getFullName(user)}
        />
    )
}
