import React from "react"

import ProfileButtonMenu from "~components/shared/header/header-profile-button-menu"
import Popover from "~components/shared/todo-lib-react-components/popover"
import HeaderProfilePopoverTrigger from "~components/shared/header/header-profile-popover-trigger"

export default function HeaderProfileButton() {
    return (
        <Popover trigger={<HeaderProfilePopoverTrigger />}>
            <ProfileButtonMenu />
        </Popover>
    )
}
