import React from "react"
import isNil from "lodash/isNil"
import { HiStar } from "react-icons/hi"
import {
    componentStyles,
    HorizontalStackSmallGap,
} from "@mallardbay/lib-react-components"

import { TEST_IDS } from "~config/test-ids"
import type { ReviewAggregationFieldsFragment } from "~graphql/generated/graphql"

// Currently not rendering the review count since there aren't many reviews yet.
// Eventually we will show rating and count: "4.3 (136)".
export default function ReviewAggregation({ reviewAggregation }: Props) {
    const styles = useStyles()
    const rating = reviewAggregation?.average_rating

    if (isNil(rating)) return null

    return (
        <HorizontalStackSmallGap
            testId={TEST_IDS.REVIEW_AGGREGATION}
            style={styles.root}
        >
            <HiStar />
            <span>{rating}</span>
        </HorizontalStackSmallGap>
    )
}

interface Props {
    readonly reviewAggregation:
        | ReviewAggregationFieldsFragment
        | null
        | undefined
}

function useStyles() {
    return componentStyles({
        root: { gap: 1 },
    })
}
