import { useEffect } from "react"
import { captureException } from "@sentry/react"
import { initAnalytics } from "@mallardbay/lib-shared-helpers"
import { globalHistory } from "@reach/router"

import { useGetUserTrackingDataQuery } from "~graphql/generated/graphql"
import {
    trackCustomPageView,
    setUserId,
} from "~utils/event-tracking/event-tracking-helpers"

export default function useAnalytics(userId?: string) {
    // This ends up being the best location to set the user id for analytics
    // to avoid dependency loops.
    setUserId(userId)

    const { loading: isLoading, data } = useGetUserTrackingDataQuery({
        onError: (err) => {
            if (err.message !== "Not Authorized") {
                captureException(err)
            }
        },
    })
    const userTrackingProps = data?.userTrackingData.props || []

    useEffect(() => {
        // TODO allow tracking for not logged in users
        const isReady = userId && !isLoading
        if (!isReady) return

        initAnalytics({
            userId,
            userTrackingProps,
            onError: (err) => captureException(err),
        })
    }, [userId, isLoading, userTrackingProps])

    // Custom page view tracking
    useEffect(() => {
        const unsubscribe = globalHistory.listen(({ location }) => {
            trackCustomPageView(location)
        })

        return () => unsubscribe()
    }, [])
}
