import React from "react"

import MobileAccountNavigatorCourierButton from "~components/pages/account/mobile-account-navigator-courier-button"
import { TEST_IDS } from "~config/test-ids"

interface Props {
    readonly onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void
}

// cannot use styling directly in this component
// for some reason the way courier renders it will cause a 'too few hooks' error
// so add any styling on the children components as needed
export default function CourierInboxMobileAnchor({ onClick }: Props) {
    return (
        <div
            onClick={onClick}
            data-testid={TEST_IDS.COURIER_INBOX_MOBILE_ANCHOR}
        >
            <MobileAccountNavigatorCourierButton />
        </div>
    )
}
