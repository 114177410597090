import React from "react"
import type { ComponentProps } from "react"

interface Props extends ComponentProps<"div"> {
    readonly shouldRender?: boolean
}

export default function ConditionalRenderContainer({
    shouldRender = false,
    children = null,
    ...divProps
}: Props) {
    if (!shouldRender) return null

    return <div {...divProps}>{children}</div>
}
