import React from "react"
import {
    RiHeartLine,
    RiMapPin2Line,
    RiInboxArchiveLine,
    RiHome2Line,
} from "react-icons/ri"
import { useLocation, useMatch } from "@reach/router"

import Box from "~components/shared/todo-lib-react-components/box"
import MobileTabBarItemProfile from "~components/shared/mobile-tab-bar/mobile-tab-bar-item-profile"
import {
    useMobileMediaQueries,
    useTabletMediaQueries,
} from "~components/shared/todo-lib-react-components/hooks/use-media-queries"
import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"
import { TEST_IDS } from "~config/test-ids"
import { useGlobalNavigation } from "~config/global-navigation-provider"

import MobileTabBarItem, { ICON_SIZE } from "./mobile-tab-bar-item"

export default function MobileTabBar() {
    const styles = useStyles()

    const isTabBarHidden = useShouldHideTabBar()
    const itemConfigs = useItemConfigs()

    if (isTabBarHidden) return null

    return (
        <Box style={styles.container} testId={TEST_IDS.MOBILE_TAB_BAR}>
            {itemConfigs.map((config) => (
                <MobileTabBarItem
                    key={config.title}
                    title={config.title}
                    to={config.to}
                    isActive={config.isActive}
                >
                    {config.icon}
                </MobileTabBarItem>
            ))}
            <MobileTabBarItemProfile />
        </Box>
    )
}

function useStyles() {
    const colors = useColors()
    const height = "68px"

    return {
        container: {
            height,
            position: "fixed",
            bottom: 0,
            width: "100vw",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
            userSelect: "none",
            backgroundColor: colors.bgColor,
            borderTop: `1px solid ${colors.borderOpaque}`,
        },
    }
}

function useShouldHideTabBar() {
    const { pathname } = useLocation()
    const { isMobileOnly } = useMobileMediaQueries()
    const { isTabletOnly } = useTabletMediaQueries()
    // If we hide the footer, we also wanna hide the mobile tab bar
    const { isMobileTabBarHidden } = useGlobalNavigation()
    if (isMobileTabBarHidden) return true

    // Do it this way so we hie it by default when rendered server side
    if (!isTabletOnly && !isMobileOnly) return true

    return [
        "/listing",
        "/book",
        "/inbox",
        "/group-pay-member",
        "/checkout-summary",
    ].some((path) => pathname.includes(path))
}

function useItemConfigs() {
    const { pathname } = useLocation()
    const isExploreActive = ["/", "/search"].includes(pathname)
    const isWishlistsActive = useMatch("/wishlists/*")
    const isTripsActive = useMatch("/trips/*")
    const isInboxActive = useMatch("/inbox/*")
    const iconStyle = {
        height: ICON_SIZE,
        width: ICON_SIZE,
    }

    return [
        {
            title: "Explore",
            icon: <RiHome2Line style={iconStyle} />,
            isActive: Boolean(isExploreActive),
            to: "/",
        },
        {
            title: "Wishlists",
            icon: <RiHeartLine style={iconStyle} />,
            isActive: Boolean(isWishlistsActive),
            to: "/wishlists",
        },
        {
            title: "Trips",
            icon: <RiMapPin2Line style={iconStyle} />,
            isActive: Boolean(isTripsActive),
            to: "/trips",
        },
        {
            title: "Inbox",
            icon: <RiInboxArchiveLine style={iconStyle} />,
            isActive: Boolean(isInboxActive),
            to: "/inbox",
        },
    ]
}
