import React from "react"
import { navigate } from "gatsby-link"
import { formatDistanceStrict } from "date-fns"
import { PillButton, componentStyles } from "@mallardbay/lib-react-components"

import type {
    ListingOutfitterFieldsFragment,
    PublicOutfitterFieldsFragment,
    ListingPublicOutfitterFieldsFragment,
} from "~graphql/generated/graphql"
import Box from "~components/shared/todo-lib-react-components/box"
import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"
import Image from "~components/shared/todo-lib-react-components/image"

interface Props {
    readonly outfitter:
        | ListingOutfitterFieldsFragment
        | PublicOutfitterFieldsFragment
        | ListingPublicOutfitterFieldsFragment
}

export default function OutfitterPopoverContent({ outfitter }: Props) {
    const styles = useStyles()

    return (
        <Box style={styles.root}>
            {outfitter.avatar?.url && (
                <Image src={outfitter.avatar.url} style={styles.image} />
            )}
            <Box style={styles.name}>{outfitter.name}</Box>
            <Box style={styles.container}>
                <Box style={styles.content}>
                    <Box
                        style={styles.location}
                    >{`${outfitter.location?.city}, ${outfitter.location?.state}`}</Box>
                    <Box style={styles.date}>
                        {`Joined ${formatDistanceStrict(
                            outfitter.created_at,
                            Date.now(),
                            { addSuffix: true }
                        )}`}
                    </Box>
                </Box>
                <PillButton
                    onClick={() => navigate(`/${outfitter.slug}`)}
                    style={styles.button}
                >
                    Visit
                </PillButton>
            </Box>
        </Box>
    )
}

function useStyles() {
    const colors = useColors()

    return componentStyles({
        root: {
            backgroundColor: colors.backgroundSecondary,
            padding: "0.8rem",
        },
        image: {
            width: "56px",
            height: "56px",
            objectFit: "cover",
            borderRadius: "50%",
            backgroundColor: "white",
            marginBottom: "0.5rem",
        },
        name: {
            fontWeight: "bold",
            marginBottom: "0.5rem",
        },
        location: {
            fontSize: "14px",
            marginBottom: "0.5rem",
        },
        container: {
            display: "flex",
            flexDirectio: "row",
            justifyContent: "space-between",
        },
        content: {
            marginRight: "0.5rem",
        },
        date: {
            fontSize: "12px",
            color: colors.secondaryLabel,
        },
        button: {
            height: "2rem",
            fontSize: "14px",
            marginTop: "1rem",
        },
    })
}
