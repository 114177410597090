import type { LegacyRef, UIEventHandler } from "react"
import React, { forwardRef } from "react"
import { Box as BoxOg } from "@chakra-ui/react"
import type { BoxProps as BoxPropsOg } from "@chakra-ui/react"

import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"

// TODO delete
interface Props extends PropsWithChildrenAndStyle {
    readonly testId?: string
    readonly id?: string
    readonly onClick?: () => void
    readonly onScroll?: UIEventHandler<HTMLDivElement>
    readonly isDisabled?: boolean
    readonly boxShadow?: BoxPropsOg["boxShadow"]
}

const Box = forwardRef(
    (
        { style, testId, isDisabled, onClick, ...props }: Props,
        ref: LegacyRef<HTMLDivElement>
    ) => {
        const handleOnClick = () => {
            if (isDisabled || !onClick) return
            onClick()
        }
        return (
            <BoxOg
                ref={ref}
                sx={style}
                data-testid={testId}
                onClick={handleOnClick}
                {...props}
            />
        )
    }
)

export default Box
