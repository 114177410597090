import { Image as ImageOg } from "@chakra-ui/react"
import React from "react"

import type {
    PropsWithChildrenAndStyle,
    PropsWithTestId,
} from "~components/shared/todo-lib-react-components/types"

interface Props extends PropsWithChildrenAndStyle, PropsWithTestId {
    readonly src: string
    readonly alt?: string
}

// TODO consolidate with `Image under `shared`

export default function Image({ style, src, alt, children, testId }: Props) {
    return (
        <ImageOg sx={style} src={src} alt={alt} data-testid={testId}>
            {children}
        </ImageOg>
    )
}
