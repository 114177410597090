import React from "react"
import type { WrapPageElementNodeArgs } from "gatsby"
import { useSetColorMode } from "@mallardbay/lib-react-components"

import Layout from "~components/shared/layout"
import type { Props as LayoutProps } from "~components/shared/layout"
import { ThemeProvider } from "~config/theme-provider"

export function WrapPageElementSsr(wrapProps: WrapPageElementNodeArgs) {
    return <WrapPageElementCore {...wrapProps} LayoutComponent={Layout} />
}

export function WrapPageElementBrowser(wrapProps: WrapPageElementNodeArgs) {
    return (
        <WrapPageElementCore
            {...wrapProps}
            LayoutComponent={LayoutForBrowser}
        />
    )
}

function WrapPageElementCore({
    element,
    props,
    LayoutComponent,
}: WrapPageElementNodeArgs & {
    readonly LayoutComponent: typeof Layout
}) {
    // props provide same data to Layout as Page element will get
    // including location, data, etc - you don't need to pass it
    return (
        <ThemeProvider>
            <LayoutComponent {...props}>{element}</LayoutComponent>
        </ThemeProvider>
    )
}

function LayoutForBrowser(props: Readonly<LayoutProps>) {
    // We do all this so we can make sure we call `useSetColorMode` only
    // client side, and also inside the theme provider
    useSetColorMode()

    return <Layout {...props} />
}
