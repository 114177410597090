import { Box, componentStyles } from "@mallardbay/lib-react-components"
import React from "react"
import type { PropsWithChildren } from "react"

export default function HiddenOnDesktopContainer({
    children,
}: PropsWithChildren) {
    const styles = useStyles()

    return <Box style={styles.root}>{children}</Box>
}

function useStyles() {
    return componentStyles({
        root: {
            display: { base: "block", md: "none" },
        },
    })
}
