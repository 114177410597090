import React, { useRef, useState } from "react"
import type { Settings } from "react-slick"
import Slider from "react-slick"
import {
    Box,
    componentStyles,
    HorizontalStackSmallGap,
    HorizontalStackSpaceBetween,
    TextSecondary,
} from "@mallardbay/lib-react-components"

import {
    useTabletMediaQueries,
    useMobileMediaQueries,
    useDesktopMediaQueries,
} from "~components/shared/todo-lib-react-components/hooks/use-media-queries"
import PageSectionHeading from "~components/shared/typography/page-section-heading"
import { TEST_IDS } from "~config/test-ids"

import ResponsiveSliderButtons from "./responsive-slider-buttons"
import "./responsive-slider.css"

const SLIDES_TO_SHOW_MOBILE = 1
const SLIDES_TO_SHOW_TABLET = 2
const SLIDES_TO_SHOW_DESKTOP = 3
const SLIDES_TO_SHOW_DEFAULT = 4

interface Props {
    readonly items: React.JSX.Element[] | React.ReactNode[]
    readonly title?: string
    readonly shouldCenterMobile?: boolean
    readonly onSwipe?: () => void
    readonly hideCount?: boolean
}

export default function ResponsiveSlider({
    items,
    title,
    shouldCenterMobile,
    onSwipe,
    hideCount,
}: Props) {
    const styles = useStyles()
    const sliderRef = useRef<Slider>(null)
    const [currentPage, setCurrentPage] = useState<number>(0)

    const itemCount = items.length
    const { slideStateLabel, shouldShowButtons } = useSliderHelper({
        itemCount,
        currentPage,
    })

    const settings = useSliderSettings({
        itemCount,
        currentPage,
        shouldCenterMobile,
    })

    return (
        <Box style={styles.root} testId={TEST_IDS.RESONSIVE_SLIDER}>
            <HorizontalStackSpaceBetween style={styles.header}>
                {title && <PageSectionHeading label={title} />}
                {shouldShowButtons && (
                    <HorizontalStackSmallGap>
                        {!hideCount && (
                            <TextSecondary>{slideStateLabel}</TextSecondary>
                        )}
                        <ResponsiveSliderButtons
                            onLeftClick={() => sliderRef.current?.slickPrev()}
                            onRightClick={() => sliderRef.current?.slickNext()}
                        />
                    </HorizontalStackSmallGap>
                )}
            </HorizontalStackSpaceBetween>
            <Slider
                {...settings}
                ref={sliderRef}
                afterChange={(slide) => setCurrentPage(slide)}
                swipeEvent={onSwipe}
            >
                {items}
            </Slider>
        </Box>
    )
}

function useSliderHelper({
    itemCount,
    currentPage,
}: {
    itemCount: number
    currentPage: number
}) {
    const slidesToShow = useSlidesToShow()
    const actualSlidesToShow = Math.min(slidesToShow, itemCount)
    const shouldBeInfinite = itemCount > slidesToShow

    if (!itemCount) {
        return {
            slideStateLabel: "",
            actualSlidesToShow,
            shouldBeInfinite,
        }
    }

    const currentPart =
        calculateSlideStateLabelPart(currentPage, actualSlidesToShow) + 1
    const totalPart = calculateSlideStateLabelPart(
        itemCount,
        actualSlidesToShow
    )
    // If we only have one page, don't show label or buttons
    const shouldShowButtons = totalPart !== 1

    const labelParts = shouldShowButtons
        ? `${currentPart} / ${totalPart}`
        : null
    return {
        slideStateLabel: labelParts,
        actualSlidesToShow,
        shouldShowButtons,
        shouldBeInfinite,
    }
}

function calculateSlideStateLabelPart(
    number: number,
    actualSlidesToShow: number
) {
    return Math.ceil(number / actualSlidesToShow)
}

function useMediaQueries() {
    const { isTabletOnly } = useTabletMediaQueries()
    const { isMobileOnly } = useMobileMediaQueries()
    const { isDesktopOnly } = useDesktopMediaQueries()

    return { isTabletOnly, isMobileOnly, isDesktopOnly }
}

function useSlidesToShow() {
    const { isMobileOnly, isTabletOnly, isDesktopOnly } = useMediaQueries()

    if (isMobileOnly) return SLIDES_TO_SHOW_MOBILE
    if (isTabletOnly) return SLIDES_TO_SHOW_TABLET
    if (isDesktopOnly) return SLIDES_TO_SHOW_DESKTOP
    return SLIDES_TO_SHOW_DEFAULT
}

function useStyles() {
    return componentStyles({
        root: { marginBottom: 10 },
        header: {
            marginBottom: 2,
            paddingX: 2,
        },
    })
}

function useSliderSettings({
    itemCount,
    currentPage,
    shouldCenterMobile = true,
}: {
    itemCount: number
    currentPage: number
    shouldCenterMobile?: boolean
}): Settings {
    const { actualSlidesToShow, shouldBeInfinite } = useSliderHelper({
        itemCount,
        currentPage,
    })
    const { isMobileOnly, isTabletOnly } = useMediaQueries()

    return {
        dots: false,
        draggable: isMobileOnly || isTabletOnly,
        centerMode: isMobileOnly && shouldCenterMobile,
        infinite: shouldBeInfinite,
        arrows: false,
        speed: 500,
        slidesToShow: actualSlidesToShow,
        slidesToScroll: actualSlidesToShow,
        initialSlide: 0,
        lazyLoad: "ondemand",
    }
}
