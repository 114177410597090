export const TEST_IDS = {
    GLOBAL_HEADER: "global-header",
    CALENDER_CONTAINER: "calender-container",
    START_DATE_BUTTON: "start-date-button",
    END_DATE_BUTTON: "end-date-button",
    FULL_TEXT_INPUT: "full-text-input",
    SEARCH_DROPDOWN: "search-dropdown",
    SEARCH_BUTTON: "search-button",
    LISTING_PREVIEW_BLOCK: "listing-preview-block",
    LISTING_DESCRIPTION: "listing-description",
    LISTING_CAROUSEL: "listing-carousel",
    LISTING_MAP_CONTAINER: "listing-map-container",
    LOCATION_MARKER: "location-marker",
    RESONSIVE_SLIDER: "responsive-slider",
    BANNER_PAGE_SECTION: "banner-page-section",
    VIDEO_PAGE_SECTION: "video-page-section",
    MAP_PAGE_SECTION: "map-page-section",
    HEADER_SEARCH_BUTTON: "header-search-button",
    HEADER_SEARCH_BAR: "header-search-bar",
    MOBILE_SEARCH_INPUT_CONTAINER: "mobile-search-input-container",
    MOBILE_SEARCH_INPUT: "mobile-search-input",
    DESKTOP_SEARCH_INPUT: "desktop-search-input",
    HEADER_SEARCH_INPUT: "header-search-input",
    SEARCH_MOBILE_MENU: "search-mobile-menu",
    MOBILE_SEARCH_VIEW: "mobile-search-view",
    MOBILE_SEARCH_BACK_BUTTON: "mobile-search-back-button",
    MOBILE_SEARCH_CARD: "mobile-search-card",
    MOBILE_SEARCH_TILE: "mobile-search-tile",
    MOBILE_SEARCH_TEXT_INPUT: "mobile-search-text-input",
    MOBILE_SEARCH_FORM: "mobile-search-form",
    MOBILE_RESET_BUTTON: "mobile-reset-button",
    ACTIVE_INDICATOR: "active-indicator",
    COURIER_BELL: "bell",
    UNREAD_MESSAGE_COUNT_BADGE: "unread-message-count-badge",
    COURIER_INBOX_MOBILE_ANCHOR: "courier-inbox-mobile-anchor",
    BOOKING_GROUP_MEMBERS_OVERVIEW: "booking-group-members-overview",
    TEXT_INPUT: "text-input",
    NUMBER_OF_GUESTS_INPUT: "NUMBER_OF_GUESTS_INPUT",
    ADD_TO_CART_BUTTON: "add-to-cart-button",
    QUANTITY_STEPPER: "quantity-stepper",
    PAYMENT_METHOD_PREVIEW: "payment-method-preview",
    PHONE_COUNTRY_SELECT: "phone-country-select",
    PHONE_NUMBER_INPUT: "phone-number-input",
    BUILDER_COMPONENT: "builder-component",
    WIDGET_MODAL: "WIDGET_MODAL",
    WIDGET_IN_LINE: "WIDGET_IN_LINE",
    BACK_BUTTON: "back-button",
    CONTAINER_WITH_RATING: "CONTAINER_WITH_RATING",
    REVIEW_AGGREGATION: "REVIEW_AGGREGATION",
    OUTFITTER_IMAGE_CARD: "OUTFITTER_IMAGE_CARD",
    LISTING_IMAGE_CARD: "LISTING_IMAGE_CARD",
    ARTICLE_IMAGE_CARD: "ARTICLE_IMAGE_CARD",
    BOOKING_IMAGE_CARD: "BOOKING_IMAGE_CARD",
    WISHLIST_BUTTON: "WISHLIST_BUTTON",
    OUTFITTER_POPOVER: "OUTFITTER_POPOVER",
    LOADING_SKELETON: "LOADING_SKELETON",
    MEDIA_GRID: "MEDIA_GRID",
    MEDIA_CAROUSEL: "MEDIA_CAROUSEL",
    CTA_BUTTON: "CTA_BUTTON",
    OUTFITTER_CONTACT_BUTTON: "OUTFITTER_CONTACT_BUTTON",
    OUTFITTER_INFO_DESKTOP: "OUTFITTER_INFO_DESKTOP",
    OUTFITTER_INFO_MOBILE: "OUTFITTER_INFO_MOBILE",
    OUTFITTER_ABOUT: "OUTFITTER_ABOUT",
    OUTFITTER_LISTINGS: "OUTFITTER_LISTINGS",
    OUTFITTER_LODGINGS: "OUTFITTER_LODGINGS",
    OUTFITTER_GUIDES: "OUTFITTER_GUIDES",
    ACTIVE_REVIEW_STAR: "ACTIVE_REVIEW_STAR",
    INACTIVE_REVIEW_STAR: "INACTIVE_REVIEW_STAR",
    OUTFITTER_REVIEWS: "OUTFITTER_REVIEWS",
    REVIEW_CARD: "REVIEW_CARD",
    OUTFITTER_AVATAR: "OUTFITTER_AVATAR",
    ASPECT_IMAGE_WITH_PILL_IMAGE: "ASPECT_IMAGE_WITH_PILL_IMAGE",
    ITINERARY: "ITINERARY",
    FEED: "FEED",
    REACTED_ICON: "REACTED_ICON",
    NOT_REACTED_ICON: "NOT_REACTED_ICON",
    GENERIC_COLLECTION_LIST: "GENERIC_COLLECTION_LIST",
    ELLIPSIS_MENU: "ELLIPSIS_MENU",
    ELLIPSIS_BUTTON: "ELLIPSIS_BUTTON",
    REGION_MAP: "REGION_MAP",
    MIN_PRICE_INPUT: "MIN_PRICE_INPUT",
    MAX_PRICE_INPUT: "MAX_PRICE_INPUT",
    MOBILE_TAB_BAR: "MOBILE_TAB_BAR",
    BOOKING_HEADER: "BOOKING_HEADER",
    BOOKING_ACTION_REQUIRED: "BOOKING_ACTION_REQUIRED",
    BOOKING_DETAILS_OVERVIEW: "BOOKING_DETAILS_OVERVIEW",
    BOOKING_DETAILS_HOST: "BOOKING_DETAILS_HOST",
    BOOKING_DETAILS_PAYMENTS: "BOOKING_DETAILS_PAYMENTS",
    BOOKING_DETAILS: "BOOKING_DETAILS",
    BOOKING_GROUP_MEMBERS: "BOOKING_GROUP_MEMBERS",
    BOOKING_DETAILS_ADDRESS: "BOOKING_DETAILS_ADDRESS",
    OWNER_SECTION: "OWNER_SECTION",
    OUTFITTER_SECTION: "OUTFITTER_SECTION",
    CONTACT_BUTTON: "CONTACT_BUTTON",
    BOOKING_DETAILS_ACTIONS: "BOOKING_DETAILS_ACTIONS",
    REFUND_TOOLTIP_LINE_ITEM: "REFUND_TOOLTIP_LINE_ITEM",
    PAYMENT_CARD: "PAYMENT_CARD",
    YEARS_OF_EXPERIENCE: "YEARS_OF_EXPERIENCE",
    SPINNER: "SPINNER",
} as const

// export both the object and the type so we don't need to do typeof TEST_IDS when we need the type where imported
type TestIdTypes = typeof TEST_IDS

export type TestId = TestIdTypes[keyof TestIdTypes]
