import React, { useState, useContext, useEffect } from "react"
import { RiHeartLine, RiHeartFill } from "react-icons/ri"
import {
    Button,
    IconButton,
    componentStyles,
} from "@mallardbay/lib-react-components"

import { UserContext } from "~config/user-provider"
import WishlistModal from "~components/pages/wishlist/wishlist-modal"
import type { WishlistFieldsFragment } from "~graphql/generated/graphql"
import {
    useWishlistsByUserLazyQuery,
    useUpdateWishlistMutation,
} from "~graphql/generated/graphql"
import { useAuth } from "~utils/auth-hooks"
import { navigateToWishlist } from "~utils/navigation-helpers"
import COPY from "~config/copy-constants"
import { TEST_IDS } from "~config/test-ids"
import { useToastWithAction } from "~components/shared/todo-lib-react-components/hooks/use-toast"
import { useTheme } from "~components/shared/todo-lib-react-components/hooks/use-theme"
import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"

interface Props {
    readonly listingId: string
    readonly isSaved?: boolean
    readonly isHeader?: boolean
    readonly isInTitle?: boolean
}

export default function WishlistButton({
    listingId,
    isSaved: initialSaved,
    isHeader,
    isInTitle,
}: Props) {
    const { isAuthenticated, loginWithRedirect } = useAuth()

    const [isOpen, setIsOpen] = useState(false)
    const [isSaved, setIsSaved] = useState(initialSaved || false)
    const [listIndex, setListIndex] = useState(-1)
    const [isCreating, setIsCreating] = useState(false)
    const [wishlists, setWishlists] = useState<WishlistFieldsFragment[]>([])
    const [isLoading, setIsLoading] = useState(true)

    const { user, isFetching } = useContext(UserContext)

    const styles = useStyles({ isHeader })

    const showToast = useRemoveFromWishlistToast({
        wishlists,
        listIndex,
    })

    const getNewListingsArray = () => {
        if (listIndex < 0) return []

        const listingArray = [
            ...(wishlists[listIndex]?.listings ?? []).map(
                (listing) => listing.id
            ),
        ]
        listingArray.splice(listingArray.indexOf(listingId), 1)
        return listingArray
    }

    const [wishlistsForUser, { data }] = useWishlistsByUserLazyQuery({
        variables: { userId: user ? user.id : "" },
        onCompleted: () => setIsCreating(false),
    })

    const [updateWishlist, { loading: isUpdating }] = useUpdateWishlistMutation(
        {
            variables: {
                id: wishlists[listIndex]?.id ?? "",
                data: {
                    listings: getNewListingsArray(),
                },
            },
            // This initial state is to fix a bug with refetching queries, I'm sure there is a cleaner
            // solution but this will do for now.
            onCompleted: () => setIsSaved(initialSaved || false),
        }
    )

    // TODO do not use useeffect here
    useEffect(() => {
        if (user && user.id) void wishlistsForUser()
    }, [user, wishlistsForUser])

    useEffect(() => {
        if (data) {
            setListIndex(-1)
            const { wishlistsByUser } = data
            setWishlists(wishlistsByUser)
        }
    }, [data])

    // Check listings of favorite lists until listingId is found, then return false to break every().
    useEffect(() => {
        if (wishlists.length === 0) {
            setIsLoading(false)
        } else {
            wishlists.every((wishlist, index) => {
                if (
                    wishlist.listings.some(
                        (listing) => listing.id === listingId
                    )
                ) {
                    setIsSaved(true)
                    setListIndex(index)
                    setIsLoading(false)
                    return false
                }
                if (index === wishlists.length - 1) {
                    setIsLoading(false)
                }
                return true
            })
        }
    }, [wishlists])

    const handleButtonClick = () => {
        if (isSaved) {
            showToast()
            void updateWishlist()
        } else if (isAuthenticated) {
            setIsOpen(true)
        } else {
            void loginWithRedirect()
        }
    }

    return (
        <>
            {isInTitle ? (
                <Button
                    data-testid={TEST_IDS.WISHLIST_BUTTON}
                    leftIcon={
                        isSaved ? (
                            <RiHeartFill style={styles.iconFill} />
                        ) : (
                            <RiHeartLine />
                        )
                    }
                    isLoading={isUpdating || isFetching || isLoading}
                    style={styles.titleButton}
                    onClick={handleButtonClick}
                    variant="ghost"
                >
                    {isSaved ? "Saved" : "Save"}
                </Button>
            ) : (
                <IconButton
                    ariaLabel={COPY.SAVE}
                    testId={TEST_IDS.WISHLIST_BUTTON}
                    style={styles.button}
                    onClick={handleButtonClick}
                    isDisabled={isUpdating || isFetching || isLoading}
                    icon={
                        isSaved ? (
                            <RiHeartFill style={styles.iconFill} />
                        ) : (
                            <RiHeartLine />
                        )
                    }
                />
            )}
            {user && (
                <WishlistModal
                    listingId={listingId}
                    wishlists={wishlists}
                    user={user}
                    isFetching={isFetching}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    isCreating={isCreating}
                    setIsCreating={setIsCreating}
                />
            )}
        </>
    )
}

function useStyles({ isHeader }: { isHeader?: boolean }) {
    const colors = useColors()
    const theme = useTheme()
    return componentStyles({
        button: {
            boxShadow: "md",
            marginLeft: 2,
            // backgroundColor: colors.bgColor,
            opacity: 0.85,
            ...(isHeader && {
                zIndex: theme.zIndices.modal,
                opacity: 1,
            }),
        },
        iconFill: {
            color: colors.errorColor,
        },
        titleButton: {
            marginLeft: "8px",
            borderRadius: "full",
            fontSize: "15px",
        },
    })
}

function useRemoveFromWishlistToast({
    wishlists,
    listIndex,
}: {
    wishlists: WishlistFieldsFragment[]
    listIndex: number
}) {
    return useToastWithAction({
        message: `${COPY.REMOVED_FROM} ${wishlists[listIndex]?.name}`,
        actionLabel: COPY.VIEW_LIST,
        onActionClick: () => navigateToWishlist(wishlists[listIndex]?.id ?? ""),
        Icon: RiHeartLine,
    })
}
