import type { PropsWithChildren } from "react"
import React from "react"
import { CourierProvider } from "@trycourier/react-provider"

import { useGetCourierInboxAuthTokenQuery } from "~graphql/generated/graphql"
import { useAuth } from "~utils/auth-hooks"

export default function CourierInboxProvider({ children }: PropsWithChildren) {
    const { isAuthenticated } = useAuth()

    // don't query if user is not signed in
    const { data } = useGetCourierInboxAuthTokenQuery({
        skip: !isAuthenticated,
    })

    return (
        <CourierProvider
            // provide a key here to force a re-render when the token changes
            // this fixes unread messages getting queried before the token is available
            key={data?.getCourierInboxAuthToken}
            authorization={data?.getCourierInboxAuthToken}
        >
            {children}
        </CourierProvider>
    )
}
