import React from "react"
import { useLocation } from "@reach/router"
import { componentStyles } from "@mallardbay/lib-react-components"

import ResponsiveBlock from "~components/shared/responsive-block"
import HiddenOnMobileContainer from "~components/shared/wrappers/hidden-on-mobile-container"
import HiddenOnDesktopContainer from "~components/shared/wrappers/hidden-on-desktop-container"
import TextLogo from "~components/shared/logo/text-logo"
import HeaderLoginButton from "~components/shared/header/header-login-button"
import HeaderProfileButton from "~components/shared/header/header-profile-button"
import OutfitterCallToActionButton from "~components/shared/header/outfitter-call-to-action-button"
import { useResponsive } from "~config/responsive-provider"
import { useAuth } from "~utils/auth-hooks"
import { HEADER_DIMENSIONS } from "~config/constants"
import { useTabletMediaQueries } from "~components/shared/todo-lib-react-components/hooks/use-media-queries"
import CourierInbox from "~components/shared/courier-inbox/courier-inbox"
import { useTheme } from "~components/shared/todo-lib-react-components/hooks/use-theme"
import Box from "~components/shared/todo-lib-react-components/box"
import SearchBar from "~components/shared/search-bar/search-bar"
import { useIsOnSearchPage } from "~utils/hooks/use-is-on-route"
import { useGlobalNavigation } from "~config/global-navigation-provider"
import { TEST_IDS } from "~config/test-ids"

import HeaderSearchBar from "./header-search-bar"

export default function Header() {
    const { isLoading, isAuthenticated } = useAuth()
    const styles = useStyles()

    const shouldRender = useShouldRenderHeader()
    const isOnSearchPage = useIsOnSearchPage()

    if (!shouldRender) return null

    return (
        <>
            <Box style={styles.overlay} testId={TEST_IDS.GLOBAL_HEADER} />
            <ResponsiveBlock style={styles.root}>
                {/* MOBILE */}
                <HiddenOnDesktopContainer>
                    <Box style={styles.headerContent}>
                        <SearchBar />
                    </Box>
                </HiddenOnDesktopContainer>

                {/* DESKTOP */}
                <HiddenOnMobileContainer>
                    <Box style={styles.headerContent}>
                        <TextLogo />
                        <HeaderSearchBar />
                        <Box style={styles.headerRight}>
                            {!isOnSearchPage && (
                                <div>
                                    <OutfitterCallToActionButton />
                                </div>
                            )}

                            <CourierInbox />
                            {(isLoading || isAuthenticated) && (
                                <HeaderProfileButton />
                            )}
                            {!isLoading && !isAuthenticated && (
                                <HeaderLoginButton />
                            )}
                        </Box>
                    </Box>
                </HiddenOnMobileContainer>
            </ResponsiveBlock>
        </>
    )
}

function useShouldRenderHeader() {
    const { pathname } = useLocation()
    const { isLargerThanTablet } = useTabletMediaQueries()
    const { isHeaderHidden } = useGlobalNavigation()

    if (isHeaderHidden) return false

    return (
        isLargerThanTablet || pathname === "/" || pathname.includes("/search")
    )
}

function useStyles() {
    const { windowScroll } = useResponsive()
    const { colors, shadows } = useTheme()
    const scrolled = windowScroll.y > 30

    // TODO fix shadow
    const mobileBoxShadow = scrolled ? shadows.base : "none"
    const backdropFilter = scrolled ? "blur(24px)" : "blur(0px)"

    return componentStyles({
        root: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            boxSizing: "border-box",
            boxShadow: { base: mobileBoxShadow, md: shadows.base },
            transition: "all 0.2s ease-in-out",
            backgroundColor: colors.bgColor,
            zIndex: 200,
            backdropFilter: { md: backdropFilter },
            WebkitBackdropFilter: { md: backdropFilter },
        },
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            pointerEvents: "none",
            transition: "all 0.2s ease-in-out",
            opacity: 0.2,
        },
        headerContent: {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: { md: "center" },
            justifyContent: { base: "center", md: "space-between" },
            height: HEADER_DIMENSIONS.HEIGHT_PX,
            paddingBottom: {
                base: HEADER_DIMENSIONS.MOBILE_BOTTOM_PADDING_PX,
                md: "0",
            },
        },
        headerRight: {
            display: "flex",
            minWidth: "200px",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "20px",
        },
    })
}
