import React from "react"
import {
    HeadingMedium,
    componentStyles,
} from "@mallardbay/lib-react-components"

import { VerticalStack } from "~components/shared/todo-lib-react-components/vertical-stack"

import type { InputSelectItemGroup as InputSelectItemGroupType } from "./input-select.types"
import InputSelectItem from "./input-select-item"

interface Props {
    readonly group: InputSelectItemGroupType
}

export default function InputSelectItemGroup({ group }: Props) {
    const styles = useStyleProps()

    if (!group.items.length) return null
    // Filter out items with empty labels
    const nonEmptyItems = group.items.filter((item) => item.label)

    return (
        <VerticalStack key={group.title} style={styles.container}>
            <HeadingMedium style={styles.heading}>{group.title}</HeadingMedium>
            {nonEmptyItems.map((item) => (
                <InputSelectItem key={item.label} item={item} />
            ))}
        </VerticalStack>
    )
}

function useStyleProps() {
    return componentStyles({
        container: {
            gap: 2,
            width: "100%",
        },
        heading: {
            marginBottom: "10px",
        },
    })
}
