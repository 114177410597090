import React from "react"
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"
import {
    HorizontalStackSmallGap,
    MediumIconButton,
} from "@mallardbay/lib-react-components"

import {
    useTabletMediaQueries,
    useMobileMediaQueries,
} from "~components/shared/todo-lib-react-components/hooks/use-media-queries"
import COPY from "~config/copy-constants"

interface Props {
    readonly onLeftClick: () => void
    readonly onRightClick: () => void
}

export default function ResponsiveSliderButtons({
    onLeftClick,
    onRightClick,
}: Props) {
    const { isTabletOnly } = useTabletMediaQueries()
    const { isMobileOnly } = useMobileMediaQueries()

    // Do not show for touch devices either
    if (isTabletOnly || isMobileOnly) return null

    const buttonConfigs = getButtonConfigs({ onLeftClick, onRightClick })

    return (
        <HorizontalStackSmallGap>
            {buttonConfigs.map((config) => (
                <MediumIconButton key={config.ariaLabel} {...config} />
            ))}
        </HorizontalStackSmallGap>
    )
}

function getButtonConfigs({ onLeftClick, onRightClick }: Props) {
    return [
        {
            ariaLabel: COPY.PREVIOUS,
            onClick: onLeftClick,
            icon: <FaChevronLeft />,
        },
        {
            ariaLabel: COPY.NEXT,
            onClick: onRightClick,
            icon: <FaChevronRight />,
        },
    ]
}
