import React from "react"

import SharedDivider from "~components/shared/todo-lib-react-components/divider"

interface Props {
    readonly margin?: boolean
}

export default function Divider({ margin = false }: Props) {
    return <SharedDivider style={{ marginY: margin ? 6 : 0 }} />
}
