import type { HTMLInputTypeAttribute, ReactNode } from "react"
import React from "react"
import type { InputProps } from "@chakra-ui/react"
import {
    InputGroup,
    Input as InputOg,
    InputLeftElement,
    InputRightElement,
} from "@chakra-ui/react"
import type { StyleProps } from "@mallardbay/lib-react-components"
import { componentStyles } from "@mallardbay/lib-react-components"

import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"

interface Props {
    readonly testId?: string
    readonly style?: StyleProps
    readonly value?: string
    readonly onChange: (value: string) => void
    readonly onClick?: () => void
    readonly onFocus?: () => void
    readonly onBlur?: () => void
    readonly hasError?: boolean
    readonly startIcon?: ReactNode
    readonly endIcon?: ReactNode
    readonly placeholder?: string
    readonly type?: HTMLInputTypeAttribute
    readonly size?: InputProps["size"]
    readonly id?: string
    readonly onKeyDown?: InputProps["onKeyDown"]
    readonly isRequired?: boolean
}

export default function Input({
    testId,
    style,
    value,
    onChange,
    onClick,
    onFocus,
    onBlur,
    startIcon,
    endIcon,
    hasError,
    placeholder,
    type,
    size,
    id,
    onKeyDown,
    isRequired,
}: Props) {
    const styles = useStyles()

    return (
        <InputGroup>
            {startIcon && <InputLeftElement>{startIcon}</InputLeftElement>}
            <InputOg
                data-testid={testId}
                sx={{ ...styles.input, ...style }}
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                isInvalid={hasError}
                isRequired={isRequired}
                type={type}
                size={size}
                onClick={onClick}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                _focusVisible={{
                    outline: "none",
                }}
            />
            {endIcon && <InputRightElement>{endIcon}</InputRightElement>}
        </InputGroup>
    )
}

function useStyles() {
    const colors = useColors()

    return componentStyles({
        input: {
            backgroundColor: colors.backgroundSecondary,
            fontSize: "md",
            "&:focus": {
                outlineColor: colors.text,
                outlineOffset: -1,
            },
        },
    })
}
