import type { PropsWithChildren } from "react"
import React from "react"
import { componentStyles } from "@mallardbay/lib-react-components"

import ResponsiveBlock from "~components/shared/responsive-block"
import PageSectionHeading from "~components/shared/typography/page-section-heading"
import CollectionButton from "~components/shared/page-sections/entity-card-collection/collection-button"
import { TEST_IDS } from "~config/test-ids"
import Box from "~components/shared/todo-lib-react-components/box"

interface Props extends PropsWithChildren {
    readonly label?: string
    readonly buttonLabel?: string | null
    readonly buttonUrl?: string | null
    readonly noPadding?: boolean
    readonly onScroll?: () => void
}

export default function GenericCollectionList({
    label,
    buttonLabel,
    buttonUrl,
    noPadding,
    onScroll,
    children,
}: Props) {
    const styles = useStyles()

    return (
        <>
            <ResponsiveBlock noPadding={noPadding}>
                {label && <PageSectionHeading label={label} />}
                <Box
                    testId={TEST_IDS.GENERIC_COLLECTION_LIST}
                    style={styles.container}
                    onScroll={onScroll}
                >
                    {children}
                </Box>
            </ResponsiveBlock>

            <Box style={styles.buttonContainer}>
                <CollectionButton label={buttonLabel} href={buttonUrl} />
            </Box>
        </>
    )
}

function useStyles() {
    return componentStyles({
        buttonContainer: {
            textAlign: "center",
            marginTop: 6,
        },
        container: {
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            maxHeight: "60vh",
            overflowY: "scroll",
        },
    })
}
