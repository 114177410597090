import React from "react"
import { componentStyles } from "@mallardbay/lib-react-components"

import SaveModalLink from "~components/pages/wishlist/save-modal-link"
import Box from "~components/shared/todo-lib-react-components/box"
import type { WishlistFieldsFragment } from "~graphql/generated/graphql"

export default function SaveModalList({
    listingId,
    wishlists,
    setIsOpen,
}: Props) {
    const styles = useStyles()

    return (
        <Box style={styles.root}>
            {wishlists.map((list) => (
                <SaveModalLink
                    key={list.id}
                    listingId={listingId}
                    wishlistId={list.id}
                    name={list.name}
                    listings={list.listings}
                    setIsOpen={setIsOpen}
                />
            ))}
        </Box>
    )
}

interface Props {
    readonly listingId: string
    readonly wishlists: WishlistFieldsFragment[]
    readonly setIsOpen: (value: boolean) => void
}

function useStyles() {
    return componentStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            maxHeight: "456px",
            overflow: "auto",
        },
    })
}
