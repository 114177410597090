import React from "react"

import type { ListingForImageCard } from "~components/shared/todo-lib-react-components/image-card/listing-image-card"
import ListingImageCard from "~components/shared/todo-lib-react-components/image-card/listing-image-card"

import GenericCollection from "./generic-collection"
import type { Props as GenericProps } from "./generic-collection"

export interface Props extends GenericProps {
    readonly listings?: Readonly<ListingForImageCard[]> | null
    readonly isLoading?: boolean
}

export default function ListingsCollection(props: Props) {
    const { listings, ...genericProps } = props

    return (
        <GenericCollection {...genericProps}>
            {listings?.map((listing) => (
                <ListingImageCard key={listing.id} listing={listing} />
            ))}
        </GenericCollection>
    )
}
