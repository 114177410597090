import React from "react"
import isNil from "lodash/isNil"
import {
    HorizontalStackSmallGap,
    TextBold,
} from "@mallardbay/lib-react-components"

import COPY from "~config/copy-constants"
import Skeleton from "~components/shared/todo-lib-react-components/skeleton"
import ConditionalRenderContainer from "~components/shared/conditional-render-container"
import type {
    ListingMinimalFieldsFragment,
    PublicListingFieldsFragment,
} from "~graphql/generated/graphql"
import { formatPrice } from "~utils/helpers"

export type ListingForPrice =
    | Pick<ListingMinimalFieldsFragment, "id" | "lowest_pricing_package">
    | Pick<PublicListingFieldsFragment, "id" | "lowest_pricing_package">
    | null
    | undefined

interface Props {
    readonly listing: ListingForPrice
    readonly isLoading?: boolean
}

export default function Price({ isLoading, listing }: Props) {
    if (isLoading) {
        return <Skeleton width="40px" />
    }
    if (!listing) return null

    const hasPrice = !isNil(listing.lowest_pricing_package)
    const lowestPrice = formatPrice(listing.lowest_pricing_package?.price)

    return (
        <ConditionalRenderContainer shouldRender={hasPrice}>
            <HorizontalStackSmallGap>
                <span>{COPY.STARTING_AT}</span>
                <TextBold>{lowestPrice}</TextBold>
            </HorizontalStackSmallGap>
        </ConditionalRenderContainer>
    )
}
