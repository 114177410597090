import React from "react"
import { Box, componentStyles } from "@mallardbay/lib-react-components"

export default function DotSeparator() {
    const styles = useStyles()

    return <Box style={styles.root}>·</Box>
}

function useStyles() {
    return componentStyles({
        root: {
            display: "inline",
            marginX: "6px",
        },
    })
}
