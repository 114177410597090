import React, {
    useContext,
    createContext,
    useState,
    type PropsWithChildren,
} from "react"
import type { ApolloError, MutationFunctionOptions } from "@apollo/client"

import { useToast } from "~components/shared/todo-lib-react-components/hooks/use-toast"
import { useAuth } from "~utils/auth-hooks"
import type {
    UserFieldsFragment,
    UpdateUserMutation,
    UpdateUserInput,
    Exact,
} from "~graphql/generated/graphql"
import { useUserQuery, useUpdateUserMutation } from "~graphql/generated/graphql"

interface IUserContext {
    user: UserFieldsFragment | undefined
    shouldSeeNewOutfitterCta: boolean
    shouldSeeNewOutfitterCtaInHeader: boolean
    // functions
    updateUser: (
        options?: MutationFunctionOptions<
            UpdateUserMutation,
            Exact<{
                data: UpdateUserInput
            }>
        >
    ) => void
    userNewData: object
    setUserNewData: (data: object) => void
    // booleans
    fetchCalled: boolean
    updateCalled: boolean
    isFetching: boolean
    isUpdating: boolean
    isImpersonating: boolean
    isAdmin: boolean
    isOutfitter: boolean
    // error
    fetchError: ApolloError | undefined
    updateError: ApolloError | undefined
}

export const UserContext = createContext<IUserContext>({
    user: undefined,
    shouldSeeNewOutfitterCta: false,
    shouldSeeNewOutfitterCtaInHeader: false,
    // functions
    updateUser: () => {},
    userNewData: {},
    setUserNewData: () => {},
    // booleans
    fetchCalled: false,
    updateCalled: false,
    isFetching: false,
    isUpdating: false,
    isImpersonating: false,
    isAdmin: false,
    isOutfitter: false,
    // error
    fetchError: undefined,
    updateError: undefined,
})

export function UserProvider({ children }: Readonly<PropsWithChildren>) {
    const userValues = useUserValues()

    return (
        <UserContext.Provider value={userValues}>
            {children}
        </UserContext.Provider>
    )
}

export function useUser() {
    return useContext(UserContext)
}

function useUserValues(): IUserContext {
    const { showSuccess, showError } = useToast() // TODO: this is not working due to User Context being outside of toast context
    const { isLoading: auth0Loading, accessToken } = useAuth()

    const [userNewData, setUserNewData] = useState({})

    const {
        called: fetchCalled,
        loading: fetchLoading,
        error: fetchError,
        data: fetchData,
    } = useUserQuery({
        skip: !accessToken,
        context: {
            headers: accessToken
                ? { authorization: `Bearer ${accessToken}` }
                : {},
        },
    })
    const user = fetchData?.user
    const isImpersonating = Boolean(
        user?.impersonation_details?.is_impersonated
    )
    // Show the new outfitter cta if we're not logged in, or if the
    // user is logged in but had no outfitters
    const shouldSeeNewOutfitterCta = Boolean(user) && !user?.is_team_member
    // If the user is not logged in at all, draw more attention to it
    const shouldSeeNewOutfitterCtaInHeader = !user

    const [
        updateUser,
        { called: updateCalled, loading: updateLoading, error: updateError },
    ] = useUpdateUserMutation({
        variables: { data: userNewData },
        context: {
            headers: accessToken
                ? { authorization: `Bearer ${accessToken}` }
                : {},
        },
        onCompleted: () => showSuccess("User profile updated successfully"),
        onError: showError,
    })

    return {
        user: fetchData?.user,
        shouldSeeNewOutfitterCta,
        shouldSeeNewOutfitterCtaInHeader,
        // functions
        updateUser,
        userNewData,
        setUserNewData,
        // booleans
        fetchCalled,
        updateCalled,
        isFetching: auth0Loading || fetchLoading,
        isUpdating: updateLoading,
        isImpersonating,
        isAdmin: Boolean(user?.is_admin),
        isOutfitter: Boolean(user?.is_team_member),
        // error
        fetchError,
        updateError,
    }
}
