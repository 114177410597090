import React from "react"
import { useToast as useToastOg, type UseToastOptions } from "@chakra-ui/react"
import type { IconType } from "react-icons"

import ToastWithActionContent from "~components/shared/todo-lib-react-components/toast-with-action-content"
import COPY from "~config/copy-constants"

const DEFAULT_ACTION_TOAST_DURATION = 8000
const COMMON_TOAST_OPTIONS: UseToastOptions = {
    position: "top",
    duration: 5000,
    isClosable: true,
}

export function useToast() {
    const showSuccess = useSuccessToast()
    const showError = useErrorToast()

    return { showSuccess, showError }
}

export function useToastWithAction({
    duration = DEFAULT_ACTION_TOAST_DURATION,
    ...contentProps
}: {
    Icon: IconType
    message: string
    iconColor?: string
    actionLabel: string
    onActionClick: () => void
    duration?: number
}) {
    const toast = useToastOg()

    return () =>
        toast({
            ...COMMON_TOAST_OPTIONS,
            duration,
            render: () => <ToastWithActionContent {...contentProps} />,
        })
}

function useSuccessToast() {
    const toast = useToastOg()

    return (description: string) =>
        toast({
            ...COMMON_TOAST_OPTIONS,
            title: COPY.TOAST_TITLES.SUCCESS,
            description,
            status: "success",
        })
}

function useErrorToast() {
    const toast = useToastOg()

    return (error?: Error) =>
        toast({
            ...COMMON_TOAST_OPTIONS,
            title: COPY.TOAST_TITLES.ERROR,
            description: error?.message,
            status: "error",
        })
}
