import type { SearchOutfittersForSportsmenInput } from "~graphql/generated/graphql"
import {
    usePublicOutfittersQuery,
    useSearchOutfittersForSportsmenQuery,
} from "~graphql/generated/graphql"

interface QueryParams {
    readonly searchParams: SearchOutfittersForSportsmenInput
    readonly options?: { from: number; size: number }
    readonly skip?: boolean
}

export const DEFAULT_SEARCH_SIZE = 20
const DEFAULT_OPTIONS = { from: 0, size: DEFAULT_SEARCH_SIZE }

function useSearchOutfitters({
    searchParams,
    options = DEFAULT_OPTIONS,
    skip,
}: QueryParams) {
    const {
        data,
        loading: isLoading,
        error,
    } = useSearchOutfittersForSportsmenQuery({
        variables: {
            searchParams: searchParams,
            options,
        },
        skip,
    })
    const outfitters = data?.searchOutfittersForSportsmen.results ?? []

    return { outfitters, isLoading, error }
}

export function useFeaturedOutfitters(
    additionalSearchParams: SearchOutfittersForSportsmenInput
) {
    return useSearchOutfitters({
        searchParams: {
            ...additionalSearchParams,
            isFeatured: true,
        },
    })
}

export function usePublicOutfitters({
    idList,
    skip,
}: {
    idList?: string[]
    skip?: boolean
} = {}) {
    return usePublicOutfittersQuery({
        variables: { ids: idList },
        skip,
    })
}
