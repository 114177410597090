import type { WatchQueryFetchPolicy } from "@apollo/client"

import type { SearchListingsForSportsmenInput } from "~graphql/generated/graphql"
import {
    usePublicListingsQuery,
    useSearchListingsForSportsmenQuery,
} from "~graphql/generated/graphql"

interface QueryParams {
    readonly searchParams: SearchListingsForSportsmenInput
    readonly options?: { from: number; size: number }
    readonly skip?: boolean
    readonly fetchPolicy?: WatchQueryFetchPolicy
}

const DEFAULT_SEARCH_SIZE = 20
const DEFAULT_OPTIONS = { from: 0, size: DEFAULT_SEARCH_SIZE }

export function useSearchListings({
    searchParams,
    options = DEFAULT_OPTIONS,
    skip,
    fetchPolicy,
}: QueryParams) {
    const {
        data,
        loading: isLoading,
        error,
    } = useSearchListingsForSportsmenQuery({
        variables: {
            searchParams: searchParams,
            options,
        },
        fetchPolicy,
        skip,
    })
    const listings = data?.searchListingsForSportsmen.results ?? []
    const total = data ? data.searchListingsForSportsmen.total : 0

    return { listings, isLoading, error, total }
}

export function useFeaturedListings() {
    return useSearchListings({
        searchParams: {
            isFeatured: true,
        },
    })
}

export function usePublicListings({
    idList,
    skip,
}: {
    idList?: string[]
    skip?: boolean
} = {}) {
    return usePublicListingsQuery({
        variables: { ids: idList },
        skip,
    })
}
