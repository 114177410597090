import React from "react"
import type { ResponsiveValue, SimpleGridProps } from "@chakra-ui/react"
import { SimpleGrid as SimpleGridOg } from "@chakra-ui/react"

import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"

interface Props extends PropsWithChildrenAndStyle {
    readonly columns?: ResponsiveValue<number>
    readonly spacing?: SimpleGridProps["spacing"]
}

export default function SimpleGrid({
    style = {},
    children,
    columns,
    spacing = "20px",
    ...props
}: Props) {
    return (
        <SimpleGridOg sx={style} columns={columns} spacing={spacing} {...props}>
            {children}
        </SimpleGridOg>
    )
}
