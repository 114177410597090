import React, { type PropsWithChildren } from "react"
import type { IconType } from "react-icons"

import ExternalLink from "~components/shared/external-link"
import MenuButtonLabelAndIcon from "~components/shared/header/menu-button-label-and-icon"

export interface Props extends PropsWithChildren {
    readonly label?: string
    readonly onClick?: () => void
    readonly Icon?: IconType
    readonly isBold?: boolean
    readonly externalLinkUrl?: string
}
export default function HeaderProfileMenuButton({
    label,
    onClick,
    Icon,
    isBold,
    externalLinkUrl,
    children,
}: Props) {
    if (externalLinkUrl) {
        return (
            <ExternalLink to={externalLinkUrl}>
                <MenuButtonLabelAndIcon
                    label={label}
                    Icon={Icon}
                    isBold={isBold}
                />
            </ExternalLink>
        )
    }

    return (
        <button type="button" onClick={onClick}>
            <MenuButtonLabelAndIcon label={label} Icon={Icon} isBold={isBold}>
                {children}
            </MenuButtonLabelAndIcon>
        </button>
    )
}
