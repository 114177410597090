import { builder } from "@builder.io/react"

import { registerCustomComponents } from "~utils/builder-io/register-custom-components"

/**
 * Reference: https://www.builder.io/c/docs/integrating-builder-pages
 * This function is to be called at the entry point of the application
 */
export function initBuilderIo() {
    builder.init(process.env.BUILDER_IO_PUBLIC_KEY ?? "")
    registerCustomComponents()
}
