import React from "react"

import type { InputSelectItemGroup as InputSelectItemGroupType } from "./input-select.types"
import InputSelectItemGroup from "./input-select-item-group"

interface Props {
    readonly itemGroups: InputSelectItemGroupType[]
}

export default function InputSelectItemGroups({ itemGroups }: Props) {
    return (
        <>
            {itemGroups.map((group) => {
                return <InputSelectItemGroup key={group.title} group={group} />
            })}
        </>
    )
}
