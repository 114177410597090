import React from "react"
import { FiSearch } from "react-icons/fi"
import {
    PillButton,
    IconButton,
    useTheme,
} from "@mallardbay/lib-react-components"

import COPY from "~config/copy-constants"
import { useIsMobile } from "~components/shared/todo-lib-react-components/hooks/use-is-mobile"

const BUTTON_COLOR_SCHEME = "brand"
interface Props {
    readonly onClick: () => void
}

export default function SearchButton({ onClick }: Props) {
    const styles = useStyles()

    const isMobile = useIsMobile()
    const icon = <FiSearch size={16} />

    const commonButtonProps = {
        colorScheme: BUTTON_COLOR_SCHEME,
        onClick,
        icon,
    }

    if (isMobile) {
        return <IconButton {...commonButtonProps} icon={icon} />
    }

    return (
        <PillButton
            {...commonButtonProps}
            leftIcon={icon}
            style={styles.button}
        >
            {COPY.SEARCH}
        </PillButton>
    )
}

function useStyles() {
    const { colors, shadows } = useTheme()
    return {
        button: {
            backgroundColor: colors.brandDefault,
            padding: "12px 25px",
            borderRadius: "3xl",
            boxShadow: shadows.base,
            fontWeight: 700,
        },
    }
}
