import type { ReactNode } from "react"
import React from "react"
import {
    Popover as PopoverOg,
    PopoverTrigger,
    PopoverContent,
    Button,
} from "@chakra-ui/react"
import type { StyleProps } from "@mallardbay/lib-react-components"
import { componentStyles } from "@mallardbay/lib-react-components"

import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"
import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"

interface Props extends PropsWithChildrenAndStyle {
    readonly trigger: ReactNode
    readonly triggerStyle?: StyleProps
}

export default function Popover({
    style,
    triggerStyle,
    trigger,
    children,
}: Props) {
    const styles = useStyles()

    return (
        <PopoverOg placement="bottom-start" defaultIsOpen={false}>
            <PopoverTrigger>
                <Button sx={triggerStyle} variant="unstyled">
                    {trigger}
                </Button>
            </PopoverTrigger>
            <PopoverContent sx={{ ...styles.content, ...style }}>
                {children}
            </PopoverContent>
        </PopoverOg>
    )
}

function useStyles() {
    const colors = useColors()

    return componentStyles({
        content: {
            backgroundColor: colors.bgColor,
            paddingY: 2,
        },
    })
}
