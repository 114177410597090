import React, { useEffect, useState } from "react"
import type { ApolloError } from "@apollo/client"
import {
    useIsDarkMode,
    IconButton,
    useTheme,
    componentStyles,
} from "@mallardbay/lib-react-components"
import { IoCloseCircle } from "react-icons/io5"

import Input from "~components/shared/todo-lib-react-components/input"
import COPY from "~config/copy-constants"
import Box from "~components/shared/todo-lib-react-components/box"
import SearchButton from "~components/shared/todo-lib-react-components/input-select/search-button"
import { VerticalStack } from "~components/shared/todo-lib-react-components/vertical-stack"
import HorizontalStackSpaceBetween from "~components/shared/todo-lib-react-components/horizontal-stack-space-between"
import { getViewportDimensions } from "~utils/window-helpers"
import { HEADER_DIMENSIONS, SEARCH_INPUT_SELECT_ID } from "~config/constants"
import { useIsMobile } from "~components/shared/todo-lib-react-components/hooks/use-is-mobile"
import { useInputSelect } from "~components/shared/todo-lib-react-components/input-select/providers/input-select-provider"

import type { InputSelectItemGroup as InputSelectItemGroupType } from "./input-select.types"
import OptionsDisplay from "./options-display"

const ENTER_KEY_NAME = "Enter"

interface Props {
    readonly itemGroups: InputSelectItemGroupType[]
    readonly isLoading?: boolean
    readonly error?: ApolloError | Error | null
    readonly onChange: (newValue: string) => void
    readonly onSearch: () => void
}

export default function InputSelectContent({
    itemGroups,
    isLoading,
    error,
    onChange,
    onSearch,
}: Props) {
    const { isCollapsed, setIsCollapsed, inputSelectContainerRef } =
        useInputSelect()
    const iconSize = 50
    const { width: containerWidth } = useContainerWidth(inputSelectContainerRef)

    const styles = useStyleProps({ containerWidth, iconSize })
    const isMobile = useIsMobile()

    const handleSearch = () => {
        onSearch()
        setIsCollapsed(true)
    }

    return (
        <Box
            style={styles.container}
            ref={inputSelectContainerRef}
            id={SEARCH_INPUT_SELECT_ID}
        >
            <HorizontalStackSpaceBetween style={styles.inputContainer}>
                <Input
                    placeholder={COPY.SEARCH_PLACEHOLDER}
                    onChange={onChange}
                    onFocus={() => setIsCollapsed(false)}
                    style={styles.input}
                    onKeyDown={(event) => {
                        if (event.key === ENTER_KEY_NAME) {
                            handleSearch()
                        }
                    }}
                />
                <SearchButton onClick={handleSearch} />
            </HorizontalStackSpaceBetween>
            {!isCollapsed && (
                <>
                    {isMobile && (
                        <IconButton
                            size="lg"
                            ariaLabel={COPY.CLOSE}
                            icon={<IoCloseCircle size={iconSize} />}
                            style={styles.closeIcon}
                            onClick={() => setIsCollapsed(true)}
                        />
                    )}
                    <VerticalStack style={styles.optionsContainer}>
                        <OptionsDisplay
                            itemGroups={itemGroups}
                            isLoading={isLoading}
                            error={error}
                        />
                    </VerticalStack>
                </>
            )}
        </Box>
    )
}

function useStyleProps({
    containerWidth,
    iconSize,
}: {
    containerWidth?: number
    iconSize: number
}) {
    const { colors, shadows, zIndices } = useTheme()

    const isDarkMode = useIsDarkMode()

    const { width: windowWidth, height: windowHeight } = getViewportDimensions()
    const height = windowHeight
        ? windowHeight -
          HEADER_DIMENSIONS.HEIGHT -
          HEADER_DIMENSIONS.MOBILE_BOTTOM_PADDING
        : "auto"

    const halfIconSize = iconSize / 2
    // Center horizontally
    const iconLeft = windowWidth ? windowWidth / 2 - halfIconSize : 0
    // Bring to bottom of page
    const iconTop = windowHeight ? windowHeight - 75 : 0
    const border = isDarkMode ? `2px solid ${colors.borderColor}` : "none"

    return componentStyles({
        container: {
            width: { base: "100%", md: "50%" },
        },
        optionsContainer: {
            position: "absolute",
            right: { base: "0px", md: "unset" },
            borderRadius: { base: 0, md: "25px" },
            backgroundColor: colors.bgColor,
            boxShadow: { base: "none", md: shadows.base },
            // to allow scolling the suggestions out from under the floating close/map buttons on mobile
            padding: { base: "15px 15px 130px 15px", md: "15px" },
            marginY: "10px",
            width: { base: windowWidth, md: containerWidth },
            maxHeight: { base: height, md: "75vh" },
            height: { base: height, md: "auto" },
            overflow: "auto",
            zIndex: zIndices.banner,
        },
        inputContainer: {
            borderRadius: "50px",
            border,
            marginTop: { base: "14px", md: 0 },
            padding: "9px",
            backgroundColor: colors.bgColor,
            boxShadow: { base: shadows.md, md: shadows.xl },
            width: "100%",
            maxHeight: "55px",
        },
        input: {
            backgroundColor: colors.bgColor,
            border: "none",
            // No outline on focus for search
            "&:focus": {
                outlineColor: "transparent",
                boxShadow: "none",
            },
        },
        closeIcon: {
            zIndex: zIndices.popover,
            position: "absolute",
            left: iconLeft,
            top: iconTop,
        },
    })
}

function useContainerWidth(ref: React.MutableRefObject<HTMLDivElement | null>) {
    const [width, setWidth] = useState(0)

    useEffect(() => {
        if (ref.current?.offsetWidth) {
            setWidth(ref.current.offsetWidth)
        }
    }, [ref.current?.offsetWidth])

    return { width }
}
