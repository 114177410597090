import React from "react"
import type { ApolloError } from "@apollo/client"
import {
    HorizontalStack,
    componentStyles,
} from "@mallardbay/lib-react-components"

import Error from "~components/shared/error"
import COPY from "~config/copy-constants"
import { TextBold } from "~components/shared/todo-lib-react-components/text"

import type { InputSelectItemGroup as InputSelectItemGroupType } from "./input-select.types"
import OptionsSkeleton from "./options-skeleton"
import InputSelectItemGroups from "./input-select-item-groups"

interface Props {
    readonly itemGroups: InputSelectItemGroupType[]
    readonly isLoading?: boolean
    readonly error?: ApolloError | Error | null
}

export default function OptionsDisplay({
    itemGroups,
    isLoading,
    error,
}: Props) {
    const styles = useStyleProps()

    const hasNoItems = itemGroups.every((group) => !group.items.length)

    if (isLoading) return <OptionsSkeleton />

    if (error) {
        return (
            <HorizontalStack style={styles.error}>
                <Error errorMsg={error.message} />
            </HorizontalStack>
        )
    }

    if (hasNoItems) {
        return (
            <TextBold style={styles.noMatches}>
                {COPY.NO_MATCHING_SUGGESTIONS}
            </TextBold>
        )
    }

    return <InputSelectItemGroups itemGroups={itemGroups} />
}
function useStyleProps() {
    return componentStyles({
        error: {
            width: "100%",
            justifyContent: "center",
            paddingBottom: "60px",
        },
        noMatches: {
            paddingLeft: "10px",
        },
    })
}
