import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    type PropsWithChildren,
} from "react"
import throttle from "lodash/throttle"

interface IResponsiveContext {
    windowScroll: IWindowScroll
}

interface IWindowScroll {
    x: number
    y: number
    isBottom: boolean
}

const defaultWindowScroll = {
    // eslint-disable-next-line id-length
    x: 0,
    // eslint-disable-next-line id-length
    y: 0,
    isBottom: false,
}

export const ResponsiveContext = createContext<IResponsiveContext>({
    windowScroll: defaultWindowScroll,
})

export function ResponsiveProvider({ children }: Readonly<PropsWithChildren>) {
    const values = useResponsiveValues()

    return (
        <ResponsiveContext.Provider value={values}>
            {children}
        </ResponsiveContext.Provider>
    )
}

export function useResponsive() {
    return useContext(ResponsiveContext)
}

function useResponsiveValues() {
    const [windowScroll, setWindowScroll] = useState(defaultWindowScroll)

    function getWindowScroll() {
        const { innerHeight, scrollX, scrollY } = window
        const { body } = document

        return {
            // eslint-disable-next-line id-length
            x: scrollX,
            // eslint-disable-next-line id-length
            y: scrollY,
            isBottom:
                body.offsetHeight > innerHeight
                    ? innerHeight + scrollY > body.offsetHeight - 1
                    : false,
        }
    }

    useEffect(() => {
        handleScroll()

        function handleScroll() {
            setWindowScroll(getWindowScroll())
        }

        const handleScrollThrottled = throttle(handleScroll, 250)

        window.addEventListener("scroll", handleScrollThrottled)

        return () => {
            window.removeEventListener("scroll", handleScrollThrottled)
        }
    }, [])

    return { windowScroll }
}
