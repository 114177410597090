import React from "react"
import type { ApolloError } from "@apollo/client"

import InputSelectProvider from "~components/shared/todo-lib-react-components/input-select/providers/input-select-provider"

import type {
    InputSelectItem,
    InputSelectItemGroup as InputSelectItemGroupType,
} from "./input-select.types"
import InputSelectContent from "./input-select-content"

interface Props {
    readonly itemGroups: InputSelectItemGroupType[]
    readonly isLoading?: boolean
    readonly error?: ApolloError | Error | null
    readonly onChange: (newValue: string) => void
    readonly onSearch: () => void
    readonly onSelection: (item: InputSelectItem) => void
}

export default function InputSelect({
    itemGroups,
    isLoading,
    error,
    onChange,
    onSearch,
    onSelection,
}: Props) {
    // TODO: [search] refactor these props into the provider
    return (
        <InputSelectProvider onSelection={onSelection}>
            <InputSelectContent
                itemGroups={itemGroups}
                isLoading={isLoading}
                error={error}
                onChange={onChange}
                onSearch={onSearch}
            />
        </InputSelectProvider>
    )
}
