import React from "react"
import {
    Box,
    componentStyles,
    useTheme,
} from "@mallardbay/lib-react-components"

import type {
    ListingOutfitterFieldsFragment,
    PublicOutfitterFieldsFragment,
    ListingPublicOutfitterFieldsFragment,
} from "~graphql/generated/graphql"
import { TEST_IDS } from "~config/test-ids"
import { Text } from "~components/shared/todo-lib-react-components/text"
import Popover from "~components/shared/todo-lib-react-components/popover"

import OutfitterPopoverContent from "./outfitter-popover-content"

interface Props {
    readonly outfitter?:
        | ListingOutfitterFieldsFragment
        | PublicOutfitterFieldsFragment
        | ListingPublicOutfitterFieldsFragment
    readonly namePrefix?: string
}

export default function OutfitterPopover({
    outfitter,
    namePrefix = "",
}: Props) {
    const styles = useStyles()
    if (!outfitter) return null

    return (
        <Popover
            trigger={
                <Box
                    style={styles.outfitter}
                    data-testid={TEST_IDS.OUTFITTER_POPOVER}
                >
                    {namePrefix}
                    <Text style={styles.outfitterName}>{outfitter.name}</Text>
                </Box>
            }
            style={styles.popover}
            triggerStyle={styles.trigger}
        >
            <OutfitterPopoverContent outfitter={outfitter} />
        </Popover>
    )
}

function useStyles() {
    const { colors } = useTheme()
    return componentStyles({
        outfitter: {
            fontSize: "14px",
            width: "fit-content",
            display: "flex",
            overflowX: "hidden",
        },
        outfitterName: {
            textDecorationLine: "underline",
            textDecorationStyle: "dotted",
            marginLeft: "0.5rem",
        },
        trigger: {
            height: "20px",
        },
        popover: {
            width: "250px",
            borderRadius: "12px",
            overflow: "hidden",
            backgroundColor: colors.backgroundSecondary,
        },
    })
}
