import React from "react"
import {
    Box,
    componentStyles,
    Image,
    TextBoldLarge,
    useIsDarkMode,
} from "@mallardbay/lib-react-components"

import ResponsiveBlock from "~components/shared/responsive-block"
import ErrorImage from "~images/error.svg"
import ErrorDarkImage from "~images/error_dark.svg"

interface Props {
    readonly errorMsg: string
    readonly noMargin?: boolean
}

export default function Error({ errorMsg, noMargin = false }: Props) {
    const styles = useStyles({ noMargin })
    const isDarkMode = useIsDarkMode()

    const imageSrc = isDarkMode ? ErrorDarkImage : ErrorImage

    return (
        <ResponsiveBlock>
            <Box style={styles.root}>
                <Image src={imageSrc} style={styles.image} />
                <TextBoldLarge>Error: {errorMsg}</TextBoldLarge>
            </Box>
        </ResponsiveBlock>
    )
}

function useStyles({ noMargin }: { noMargin: boolean }) {
    return componentStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: noMargin ? "0" : 12,
        },
        image: {
            width: "184px",
            height: "184px",
            marginBottom: 6,
        },
    })
}
