import type { GenericSearchSuggestionGroup } from "~graphql/generated/graphql"
import { useGetSearchSuggestionsForTripQuery } from "~graphql/generated/graphql"
import COPY from "~config/copy-constants"
import { SearchSuggestionItemTypes, ICON_URLS } from "~config/constants"

import { useSearchHistory } from "./use-search-history"
import { getSearchRouteWithQueryTerm } from "./navigation-helpers"

const STATE_TYPE = "State"

export default function useSearchSuggestionsForTrip({
    text,
    shouldIncludeSearchHistory = true,
}: {
    text: string
    shouldIncludeSearchHistory?: boolean
}) {
    const {
        data,
        loading: isLoading,
        error,
    } = useGetSearchSuggestionsForTripQuery({
        variables: {
            searchParams: {
                text,
            },
        },
    })

    const searchSuggestions = data?.getSearchSuggestionsForTrip ?? []
    const historySuggestions = useHistory()

    const allSuggestions = shouldIncludeSearchHistory
        ? [historySuggestions, ...searchSuggestions]
        : searchSuggestions

    return {
        searchSuggestions: allSuggestions,
        isLoading,
        error,
    }
}

export function useStateSuggestions() {
    const { searchSuggestions, isLoading, error } = useSearchSuggestionsForTrip(
        { text: "" }
    )

    const stateSuggestions = searchSuggestions.find(
        (suggestion) => suggestion.type === STATE_TYPE
    )

    return {
        items: stateSuggestions?.items ?? [],
        isLoading,
        error,
    }
}

function useHistory(): GenericSearchSuggestionGroup {
    const { history } = useSearchHistory()
    return {
        title: COPY.RECENT_SEARCHES,
        type: SearchSuggestionItemTypes.SearchHistory,
        items: history.map((searchTerm) => ({
            label: searchTerm,
            value: searchTerm,
            type: SearchSuggestionItemTypes.SearchHistory,
            cta_url: getSearchRouteWithQueryTerm(searchTerm),
            icon_url: ICON_URLS.RECENT,
        })),
    }
}
