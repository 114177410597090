import React, { type PropsWithChildren } from "react"
import {
    componentStyles,
    ExternalLink as ExternalLinkOg,
    useTheme,
} from "@mallardbay/lib-react-components"

interface Props extends PropsWithChildren {
    readonly to: string
    readonly colored?: boolean
    readonly underline?: boolean
    readonly id?: string
}

export default function ExternalLink({
    to,
    colored,
    underline,
    children,
    id,
}: Props) {
    const styles = useStyleProps({ isColored: colored })
    return (
        <ExternalLinkOg
            id={id}
            href={to}
            rel="noopener noreferrer"
            isUnderlined={!!underline}
            style={styles.link}
        >
            {children}
        </ExternalLinkOg>
    )
}

function useStyleProps({ isColored }: { isColored?: boolean }) {
    const { colors } = useTheme()

    return componentStyles({
        link: {
            fontWeight: 600,
            color: isColored ? "#ff6c0d" : colors.contentPrimary,
        },
    })
}
