import { useLocation } from "@reach/router"

function useIsOnRoute(route?: string) {
    const { pathname } = useLocation()

    if (!route) return false

    return pathname && pathname.startsWith(route)
}

export function useIsOnSearchPage() {
    return useIsOnRoute("/search")
}
