import React from "react"
import type { WrapRootElementNodeArgs } from "gatsby"

// Needed for react-slick: https://github.com/akiran/react-slick?tab=readme-ov-file#installation
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import ApolloProvider from "~apollo/provider"
import { UserProvider } from "~config/user-provider"
import { ResponsiveProvider } from "~config/responsive-provider"
import { AUTH_REDIRECT_ROUTE } from "~config/constants"
import { initBuilderIo } from "~utils/builder-io/builder-io-helpers"
import CourierInboxProvider from "~config/courier-inbox-provider"
import Auth0Provider from "~config/auth0-provider"
import { GlobalNavigationProvider } from "~config/global-navigation-provider"

initBuilderIo()

export function WrapRootElementSsr(props: Readonly<WrapRootElementNodeArgs>) {
    const redirectUri = ""

    return <WrapRootElementCore redirectUri={redirectUri} {...props} />
}

export function WrapRootElementBrowser(
    props: Readonly<WrapRootElementNodeArgs>
) {
    const redirectUri = `${window.location.origin}${AUTH_REDIRECT_ROUTE}`

    return <WrapRootElementCore redirectUri={redirectUri} {...props} />
}

function WrapRootElementCore({
    element,
    redirectUri,
}: Readonly<WrapRootElementNodeArgs> & { readonly redirectUri: string }) {
    return (
        <Auth0Provider redirectUri={redirectUri}>
            <GlobalNavigationProvider>
                <ApolloProvider>
                    <ResponsiveProvider>
                        <UserProvider>
                            <CourierInboxProvider>
                                {element}
                            </CourierInboxProvider>
                        </UserProvider>
                    </ResponsiveProvider>
                </ApolloProvider>
            </GlobalNavigationProvider>
        </Auth0Provider>
    )
}
