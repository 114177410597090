import {
    RiShieldCheckLine,
    RiCalendarLine,
    RiCustomerServiceLine,
    RiLogoutCircleRLine,
    RiMapPin2Line,
    RiInboxArchiveLine,
    RiHeartLine,
} from "react-icons/ri"

import { useUser } from "~config/user-provider"
import { useAuth } from "~utils/auth-hooks"
import {
    NAVIGATION_URLS,
    navigateToGuidetechPage,
    navigateToAccountPage,
    navigateToHelpPage,
    navigateToInboxPage,
    navigateToTripsPage,
    navigateToWishlistsPage,
} from "~utils/navigation-helpers"
import COPY from "~config/copy-constants"

export function useFirstSectionButtonConfigs() {
    return [
        {
            label: COPY.TRIPS,
            Icon: RiMapPin2Line,
            onClick: navigateToTripsPage,
            isBold: true,
        },
        {
            label: COPY.INBOX,
            Icon: RiInboxArchiveLine,
            onClick: navigateToInboxPage,
            isBold: true,
        },
        {
            label: COPY.WISHLISTS,
            Icon: RiHeartLine,
            onClick: navigateToWishlistsPage,
            isBold: true,
        },
    ]
}

export function useSecondSectionButtonConfigs() {
    const { shouldSeeNewOutfitterCta } = useUser()

    return [
        {
            label: COPY.ACCOUNT,
            onClick: navigateToAccountPage,
        },
        ...(shouldSeeNewOutfitterCta
            ? [
                  {
                      label: COPY.BECOME_AN_OUTFITTER,
                      onClick: navigateToGuidetechPage,
                  },
              ]
            : []),
        {
            label: COPY.HELP,
            Icon: RiCustomerServiceLine,
            onClick: navigateToHelpPage,
        },
    ]
}

export function useThirdSectionButtonConfigs() {
    const { logout } = useAuth()
    const { isAdmin, isOutfitter } = useUser()

    const conditionalButtonConfigs = []
    const externalLinkUrl = NAVIGATION_URLS.EXTERNAL.ADMIN_DASHBOARD

    if (isAdmin) {
        conditionalButtonConfigs.push({
            label: COPY.ADMIN_DASHBOARD,
            Icon: RiShieldCheckLine,
            externalLinkUrl,
        })
    }

    if (isOutfitter) {
        conditionalButtonConfigs.push({
            label: COPY.MANAGE_LISTINGS,
            Icon: RiCalendarLine,
            externalLinkUrl,
        })
    }

    return [
        ...conditionalButtonConfigs,
        {
            label: COPY.LOG_OUT,
            Icon: RiLogoutCircleRLine,
            onClick: () => logout(),
        },
    ]
}
