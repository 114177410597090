import type { PropsWithChildren } from "react"
import React, { useMemo } from "react"
import {
    Provider,
    useCoreThemeForProvider,
} from "@mallardbay/lib-react-components"
// TODO: Right now we need to use the ChakraProvider along with the Provider
// from lib-react-components, otherwise the toasts don't work. We can address
// this once we're done converting everything as a last step.
// eslint-disable-next-line no-restricted-imports
import { ChakraProvider } from "@chakra-ui/react"

export function ThemeProvider({ children }: Readonly<PropsWithChildren>) {
    const theme = useCoreThemeForProvider()

    const themeToUse = useMemo(() => {
        return {
            ...theme,
            ...getThemeGlobalStyles(),
        }
    }, [theme])

    return (
        <Provider theme={themeToUse}>
            <ChakraProvider theme={themeToUse}>{children}</ChakraProvider>
        </Provider>
    )
}

// These are setup so we can style builder.io components
// with the same styles as the rest of the site
// Use css vars so they account for color mode
// They matcj styles from our components so they should be in sync
function getThemeGlobalStyles() {
    return {
        styles: {
            global: {
                // eslint-disable-next-line id-length
                a: {
                    color: "brand.400",
                },
                "h1, h2, h3, h4, h5, h6": {
                    fontFamily: "var(--chakra-fonts-heading)",
                    fontWeight: "var(--chakra-fontWeights-bold)",
                },
                "h1, h2, h3, h4, h5, h6, p": {
                    color: "var(--chakra-colors-chakra-body-text)",
                },
                "h1:not(.chakra-heading)": {
                    fontSize: "var(--chakra-fontSizes-3xl) !important",
                },
                "h2:not(.chakra-heading)": {
                    fontSize: "var(--chakra-fontSizes-2xl) !important",
                },
                "h3:not(.chakra-heading)": {
                    fontSize: "var(--chakra-fontSizes-xl) !important",
                },
                "h4:not(.chakra-heading)": {
                    fontSize: "var(--chakra-fontSizes-lg) !important",
                },
                "h5:not(.chakra-heading)": {
                    fontSize: "var(--chakra-fontSizes-md) !important",
                },
                "h6:not(.chakra-heading)": {
                    fontSize: "var(--chakra-fontSizes-sm) !important",
                },
            },
        },
    }
}
