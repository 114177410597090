import React from "react"
import {
    HorizontalStack,
    componentStyles,
} from "@mallardbay/lib-react-components"
import { navigate } from "gatsby-link"

import { useTheme } from "~components/shared/todo-lib-react-components/hooks/use-theme"
import { Text } from "~components/shared/todo-lib-react-components/text"
import Image from "~components/shared/todo-lib-react-components/image"
import Box from "~components/shared/todo-lib-react-components/box"
import { useInputSelect } from "~components/shared/todo-lib-react-components/input-select/providers/input-select-provider"
import { SUGGESTION_IMG_HEIGHT } from "~config/constants"

import type { InputSelectItem as InputSelectItemType } from "./input-select.types"

interface Props {
    readonly item: InputSelectItemType
}

export default function InputSelectItem({ item }: Props) {
    const styles = useStyleProps()
    const hasImage = Boolean(item.image_url) || Boolean(item.icon_url)
    const url = item.image_url ?? item.icon_url
    const imageStyle = item.image_url ? styles.image : styles.icon
    const { onSelection, setIsCollapsed } = useInputSelect()

    return (
        <Box
            style={styles.container}
            onClick={() => handleOnClick({ item, onSelection, setIsCollapsed })}
        >
            <HorizontalStack>
                {hasImage && (
                    <Image style={imageStyle} src={url!} alt={item.label} />
                )}
                <Text style={styles.text}>{item.label}</Text>
            </HorizontalStack>
        </Box>
    )
}

function useStyleProps() {
    const { colors } = useTheme()

    const commonImageProps = {
        placeItems: "center",
        width: SUGGESTION_IMG_HEIGHT,
        height: SUGGESTION_IMG_HEIGHT,
        overflow: "hidden",
        transform: "translateZ(0)",
        backgroundColor: colors.backgroundSecondary,
        borderRadius: "5px",
        border: `1px solid ${colors.borderColor}`,
    }

    return componentStyles({
        container: {
            cursor: "pointer",
            width: "100%",
            ":hover": {
                backgroundColor: colors.backgroundSecondary,
            },
        },
        image: {
            ...commonImageProps,
            padding: "0px",
        },
        icon: {
            ...commonImageProps,
            padding: "10px",
        },
        text: {
            flex: 4,
        },
    })
}

function handleOnClick({
    item,
    onSelection,
    setIsCollapsed,
}: {
    item: InputSelectItemType
    onSelection: (item: InputSelectItemType) => void
    setIsCollapsed: (isCollapsed: boolean) => void
}) {
    setIsCollapsed(true)
    // If we can navigate do that, if not we set the selection
    if (item.cta_url) {
        void navigate(item.cta_url)
        return
    }
    // Call onSelection so components using input-select can respond to the selection
    onSelection(item)
}
