import { useLocalStorage } from "usehooks-ts"

import { LOCAL_STORAGE_KEYS } from "~config/constants"

const MAX_HISTORY_SIZE = 5

export function useSearchHistory() {
    const [value, setValue] = useLocalStorage<string[]>(
        LOCAL_STORAGE_KEYS.SEARCH,
        []
    )

    const appendToHistory = (newItem?: string) => {
        if (!newItem) return

        setValue((currentHistory) =>
            addUniqueItemToArray({ currentHistory, newItem })
        )
    }

    return {
        history: value,
        appendToHistory,
        clearHistory: () => setValue([]),
    }
}

function addUniqueItemToArray({
    currentHistory,
    newItem,
}: {
    currentHistory: string[]
    newItem: string
}) {
    const currentLessRepease = new Set(currentHistory)
    const newList = [newItem, ...currentLessRepease].slice(0, MAX_HISTORY_SIZE)
    const uniqueList = new Set(newList)
    return Array.from(uniqueList)
}
