import type { PropsWithChildren } from "react"
import React from "react"
import { useInbox } from "@trycourier/react-hooks"
import { componentStyles } from "@mallardbay/lib-react-components"

import { TEST_IDS } from "~config/test-ids"
import Box from "~components/shared/todo-lib-react-components/box"
import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"

export default function UnreadMessageCountBadge({
    children,
}: PropsWithChildren) {
    const styles = useStyles({ hasChildren: Boolean(children) })
    const { unreadMessageCount } = useInbox()

    return (
        <Box style={styles.root}>
            <Box
                testId={TEST_IDS.UNREAD_MESSAGE_COUNT_BADGE}
                style={styles.badge}
            >
                {unreadMessageCount}
            </Box>
            {children}
        </Box>
    )
}

function useStyles({ hasChildren }: { hasChildren: boolean }) {
    const colors = useColors()

    return componentStyles({
        root: {
            position: "relative",
        },
        badge: {
            position: hasChildren ? "absolute" : "relative",
            top: hasChildren ? "-10px" : "0",
            right: hasChildren ? "-10px" : "0",
            borderRadius: "full",
            backgroundColor: colors.primaryButtonColor,
            width: "20px",
            height: "20px",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
        },
    })
}
