import { useTheme } from "@mallardbay/lib-react-components"
import { useMediaQuery } from "usehooks-ts"

const ZERO = "0"
const EM = "em"

export function useMobileMediaQueries() {
    const { breakpoints } = useTheme()
    const mobile = getMediaQueryExpressionFromValue(breakpoints.sm)
    const mobileOnly = getMediaQueryExpressionFromValue([ZERO, breakpoints.sm])

    const isLargerThanMobile = useMediaQuery(mobile)
    const isMobileOnly = useMediaQuery(mobileOnly)

    return {
        isLargerThanMobile,
        isMobileOnly,
    }
}

export function useTabletMediaQueries() {
    const { breakpoints } = useTheme()
    const tablet = getMediaQueryExpressionFromValue(breakpoints.md)
    const tabletOnly = getMediaQueryExpressionFromValue([
        add1Em(breakpoints.sm),
        breakpoints.md,
    ])
    const isLargerThanTablet = useMediaQuery(tablet)
    const isTabletOnly = useMediaQuery(tabletOnly)

    return {
        isLargerThanTablet,
        isTabletOnly,
    }
}

export function useDesktopMediaQueries() {
    const { breakpoints } = useTheme()
    const desktop = getMediaQueryExpressionFromValue(breakpoints.lg)
    const desktopOnly = getMediaQueryExpressionFromValue([
        add1Em(breakpoints.md),
        breakpoints.lg,
    ])
    const isLargerThanDesktop = useMediaQuery(desktop)
    const isDesktopOnly = useMediaQuery(desktopOnly)

    return {
        isLargerThanDesktop,
        isDesktopOnly,
    }
}

function getMediaQueryExpressionFromValue(
    value: string | readonly [string, string]
) {
    const prefix = "screen and"
    if (!Array.isArray(value)) return `${prefix} (min-width: ${value})`

    // If value[0] is 0, only use max-width
    if (value[0] === "0") {
        return `${prefix} (max-width: ${value[1]})`
    }

    return `${prefix} (min-width: ${value[0]}) and (max-width: ${value[1]})`
}

// Assumes emString is in the according the following regex [1-9]+em
function add1Em(emString: string) {
    const [numericString] = emString.split(EM)
    if (!numericString) {
        console.error("could not parse emString")
        return "0em"
    }
    const emPlus1 = parseInt(numericString, 10) + 1

    return `${emPlus1}${EM}`
}
