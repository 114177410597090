import React from "react"

import SearchBar from "~components/shared/search-bar/search-bar"
import { useIsOnSearchPage } from "~utils/hooks/use-is-on-route"

export default function HeaderSearchBar() {
    const isOnSearchPage = useIsOnSearchPage()
    if (!isOnSearchPage) return null

    return <SearchBar />
}
