import React, { type JSX } from "react"
import { useLocation } from "@reach/router"
import { Script } from "gatsby"
import {
    Box,
    componentStyles,
    useTheme,
} from "@mallardbay/lib-react-components"

import Header from "~components/shared/header/header"
import Footer from "~components/shared/footer"
import MobileTabBar from "~components/shared/mobile-tab-bar/mobile-tab-bar"
import useAnalytics from "~utils/hooks/use-analytics"
import { useUser } from "~config/user-provider"
import WelcomeModal from "~components/pages/welcome-modal"

import ImpersonationBox from "./auth/impersonation-box"

// Can't use PropsWithChildren here.
export interface Props {
    readonly children: JSX.Element
}

export default function Layout({ children }: Props) {
    const styles = useStyles()
    const {
        shouldShowHeader,
        shouldShowFooter,
        shouldShowTabbar,
        shouldShowImpersonationBox,
    } = useRouteOptions()

    const { user } = useUser()
    useAnalytics(user?.id)

    return (
        <>
            <Box style={styles.root}>
                <Box as="main" style={styles.content}>
                    {children}
                </Box>
                {shouldShowHeader && <Header />}
                {shouldShowFooter && <Footer />}
                {shouldShowTabbar && <MobileTabBar />}
                {shouldShowImpersonationBox && <ImpersonationBox />}
            </Box>
            <WelcomeModal />
            {/* See docs https://studio.appcues.com/settings/installation/guide/overview */}
            <Script type="text/javascript">
                {`window.AppcuesSettings = { enableURLDetection: true };`}
            </Script>
            <Script src="//fast.appcues.com/209636.js" />
        </>
    )
}

function useStyles() {
    const { pathname } = useLocation()
    const { shouldShowHeader } = useRouteOptions()
    const { colors } = useTheme()

    return componentStyles({
        root: {
            backgroundColor: colors.backgroundPrimary,
            color: colors.contentPrimary,
            overflowX: "hidden",
        },
        content: {
            position: "relative",
            paddingTop: {
                base: "0",
                md: pathname === "/" || !shouldShowHeader ? 0 : "72px",
            },
            marginTop: "0",
            marginRight: "auto",
            marginBottom: "0",
            marginLeft: "auto",
            minHeight: { base: "100dvh", md: "calc(100vh - 72px)" },
        },
        loadingScreen: {
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: colors.contentPrimary,
            backgroundColor: colors.backgroundPrimary,
        },
    })
}

function useRouteOptions() {
    const { pathname } = useLocation()
    const isWidgetRoute = pathname.startsWith("/widget")

    // This could be more robust, but for now it works well
    if (isWidgetRoute) {
        return {
            shouldShowHeader: false,
            shouldShowFooter: false,
            shouldShowTabbar: false,
            shouldShowImpersonationBox: false,
        }
    }

    return {
        shouldShowHeader: true,
        shouldShowFooter: true,
        shouldShowTabbar: true,
        shouldShowImpersonationBox: true,
    }
}
