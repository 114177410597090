import type { PropsWithChildren } from "react"
import React from "react"
import { componentStyles } from "@mallardbay/lib-react-components"

import ResponsiveBlock from "~components/shared/responsive-block"
import PageSectionHeading from "~components/shared/typography/page-section-heading"
import CollectionButton from "~components/shared/page-sections/entity-card-collection/collection-button"
import SimpleGrid from "~components/shared/todo-lib-react-components/simple-grid"
import Box from "~components/shared/todo-lib-react-components/box"

interface Props extends PropsWithChildren {
    readonly label?: string
    readonly buttonLabel?: string | null
    readonly buttonUrl?: string | null
    readonly noPadding?: boolean
}

export default function GenericCollectionGrid({
    label,
    buttonLabel,
    buttonUrl,
    children,
    noPadding,
}: Props) {
    const styles = useStyles()

    return (
        <>
            <ResponsiveBlock noPadding={noPadding}>
                {label && <PageSectionHeading label={label} />}
                <SimpleGrid style={{ marginTop: 4 }} columns={[1, 2, 2, 3, 4]}>
                    {children}
                </SimpleGrid>
            </ResponsiveBlock>

            <Box style={styles.buttonsContainer}>
                <CollectionButton label={buttonLabel} href={buttonUrl} />
            </Box>
        </>
    )
}

function useStyles() {
    return componentStyles({
        buttonsContainer: {
            textAlign: "center",
            marginTop: 6,
        },
    })
}
