import { Avatar as AvatarOg } from "@chakra-ui/react"
import React from "react"

import type { PropsWithTestId } from "./types"

export enum Size {
    XXSmall = "2xs",
    XSmall = "xs",
    Small = "sm",
    Medium = "md",
    Large = "lg",
    XLarge = "xl",
    XXLarge = "2xl",
}

export interface Props extends PropsWithTestId {
    readonly size?: Size
    readonly name?: string
    readonly src?: string
}

export default function Avatar({ testId, ...props }: Props) {
    return <AvatarOg data-testid={testId} {...props} />
}
