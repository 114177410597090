import React, { useState } from "react"
import { Button, componentStyles } from "@mallardbay/lib-react-components"

import MbModal from "~components/shared/mb-modal"
import SaveModalList from "~components/pages/wishlist/save-modal-list"
import type {
    UserFieldsFragment,
    WishlistFieldsFragment,
    WishlistsByUserQueryVariables,
} from "~graphql/generated/graphql"
import {
    useCreateWishlistMutation,
    WishlistsByUserDocument,
} from "~graphql/generated/graphql"
import Box from "~components/shared/todo-lib-react-components/box"
import Input from "~components/shared/todo-lib-react-components/input"
import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"
import Spinner from "~components/shared/todo-lib-react-components/spinner"

interface WishlistModalProps {
    readonly listingId: string
    readonly wishlists: WishlistFieldsFragment[]
    readonly user: UserFieldsFragment
    readonly isFetching?: boolean
    readonly isOpen: boolean
    readonly setIsOpen: (value: boolean) => void
    readonly isCreating: boolean
    readonly setIsCreating: (value: boolean) => void
}

export default function WishlistModal({
    listingId,
    wishlists,
    user,
    isFetching,
    isOpen,
    setIsOpen,
    isCreating,
    setIsCreating,
}: WishlistModalProps) {
    const styles = useStyles()
    const [listName, setListName] = useState("")

    const [createWishlist, { loading }] = useCreateWishlistMutation({
        variables: {
            data: {
                name: listName,
                user: user.id,
                listings: [],
            },
        },
        refetchQueries: () => [
            {
                query: WishlistsByUserDocument,
                variables: { userId: user.id } as WishlistsByUserQueryVariables,
            },
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
            setListName("")
            setIsCreating(false)
        },
    })

    return (
        <MbModal
            title={isCreating ? "Create a new wishlist" : "Save to a wishlist"}
            onClose={() => {
                setIsCreating(false)
                if (!isCreating) setIsOpen(false)
            }}
            isOpen={isOpen}
        >
            {isFetching ? (
                <Box style={styles.dotsContainer}>
                    <Spinner />
                </Box>
            ) : (
                <>
                    <Box style={styles.listContainer}>
                        {isCreating ? (
                            <Input
                                value={listName}
                                onChange={(value) => setListName(value)}
                                placeholder="Name"
                            />
                        ) : (
                            <SaveModalList
                                listingId={listingId}
                                wishlists={wishlists}
                                setIsOpen={setIsOpen}
                            />
                        )}
                    </Box>
                    <Box>
                        {isCreating ? (
                            <Button
                                isLoading={loading}
                                disabled={listName.length < 1}
                                style={styles.button}
                                onClick={() => createWishlist()}
                            >
                                <Box style={styles.buttonText}>Create</Box>
                            </Button>
                        ) : (
                            <Button
                                style={styles.button}
                                onClick={() => setIsCreating(true)}
                            >
                                <Box style={styles.buttonText}>
                                    Create a list
                                </Box>
                            </Button>
                        )}
                    </Box>
                </>
            )}
        </MbModal>
    )
}

function useStyles() {
    const colors = useColors()
    return componentStyles({
        button: {
            width: "100%",
            borderRadius: 3,
            backgroundColor: colors.backgroundPrimary,
            height: "4rem",
        },
        buttonText: {
            width: "100%",
            fontWeight: "bold",
            textAlign: "center",
            textDecoration: "underline",
        },
        dotsContainer: {
            paddingBottom: "48px",
            minWidth: { sm: "400px" },
        },
        listContainer: {
            alignContent: "center",
        },
    })
}
