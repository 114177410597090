import React from "react"
import { Accordion } from "@mallardbay/lib-react-components"
import type { AccordionProps } from "@mallardbay/lib-react-components"

type Props = {
    readonly accordionItems?: {
        readonly title?: string
        readonly description?: string
    }[]
}

export default function BuilderioAccordion(props: Props) {
    const mappedProps = mapBuilderioProps(props)

    return <Accordion {...mappedProps} />
}

// Exported for tests
export function mapBuilderioProps(props: Props): {
    items: AccordionProps["items"]
} {
    const accordionItems = (props.accordionItems ?? []).map((item) => {
        return {
            title: item.title,
            content: item.description,
        }
    })

    return {
        items: accordionItems,
    }
}
