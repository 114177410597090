import React from "react"
import {
    componentStyles,
    VerticalStack,
} from "@mallardbay/lib-react-components"

import OptionsSkeletonGroup from "./options-skeleton-group"

export default function OptionsSkeleton() {
    const styles = useStyleProps()

    return (
        <VerticalStack style={styles.container}>
            <OptionsSkeletonGroup />
            <OptionsSkeletonGroup />
        </VerticalStack>
    )
}

function useStyleProps() {
    return componentStyles({
        container: { gap: 9 },
    })
}
