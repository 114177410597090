// eslint-disable-next-line filenames/match-regex,filenames/match-exported -- so we can keep auth0 instead of auth-0
import React, { type PropsWithChildren } from "react"
import { navigate } from "gatsby-link"
import { Auth0Provider as Auth0ProviderOg } from "@auth0/auth0-react"

export default function Auth0Provider({
    redirectUri,
    children,
}: Readonly<PropsWithChildren> & { readonly redirectUri: string }) {
    return (
        <Auth0ProviderOg
            domain={process.env.GATSBY_AUTH0_DOMAIN!}
            clientId={process.env.GATSBY_AUTH0_CLIENT_ID!}
            authorizationParams={{
                redirect_uri: redirectUri,
                audience: process.env.GATSBY_AUTH0_AUDIENCE,
            }}
            onRedirectCallback={(appState) => {
                void navigate(appState?.returnTo || window.location.pathname, {
                    replace: true,
                })
            }}
            cacheLocation="localstorage"
        >
            {children}
        </Auth0ProviderOg>
    )
}
