import React from "react"

import type { Props as OutfittersQueryableProps } from "~components/shared/collections/outfitters-collection-queryable"
import OutfittersCollectionQueryable from "~components/shared/collections/outfitters-collection-queryable"
import ListingsCollectionQueryable from "~components/shared/collections/listings-collection-queryable"
import type { Props as ListingsQueryableProps } from "~components/shared/collections/listings-collection-queryable"

// TODO move all builder specific components to its own folder
interface SharedBuilderProps {
    // somehow builder.io sometimes sends items without an id, so
    // its better to have a type where id is optional
    readonly idMaps?: { id?: string }[]
}

export type OutfittersProps = Omit<OutfittersQueryableProps, "idList"> &
    SharedBuilderProps

export type ListingsProps = Omit<ListingsQueryableProps, "idList"> &
    SharedBuilderProps

export function BuilderioOutfittersCollection(props: OutfittersProps) {
    const mappedProps = mapBuilderioProps(props)

    return <OutfittersCollectionQueryable {...mappedProps} />
}

export function BuilderioListingsCollection(props: ListingsProps) {
    const mappedProps = mapBuilderioProps(props)

    return <ListingsCollectionQueryable {...mappedProps} />
}

function mapBuilderioProps<P extends SharedBuilderProps>(props: P) {
    const idList = (props.idMaps || [])
        .map(({ id }) => id?.toString())
        // https://www.benmvp.com/blog/filtering-undefined-elements-from-array-typescript/
        .filter((id): id is string => !!id)

    return {
        ...props,
        idList,
    }
}
