// Export mutable version so we can pass to the main SEO component.
// If we were to use it from the main export we get TS errors
export const SEO_KEYWORDS = [
    "hunting",
    "fishing",
    "outfitter",
    "fishing charter",
    "calendar managment software",
    "book your hunt",
    "big game",
    "small game",
    "waterfowl guides",
    "guidetech",
    "mallard bay outdoors",
]

export default {
    LET_US_GET_TO_KNOW_YOU: "Let us get to know you",
    DATE_RANGE: "Date range",
    REVIEW_SUBMITTED_SUCCESSFULLY: "Review submitted successfully!",
    LIST: "List",
    MAP: "Map",
    HERE: "here",
    SEARCH: "Search",
    STARTING_AT: "Starting at",
    PRICING_UNAVAILABLE: "Pricing unavailable",
    SHOW_DETAILS: "Show details",
    HIDE_DETAILS: "Hide details",
    TRIP_DETAILS: "Trip details",
    TRIP_SUMMARY: "Trip summary",
    LEAVE_A_REVIEW: "Leave a review",
    CLEAR_DATES: "Clear dates",
    SELECT_DATES: "Select dates",
    SELECT_YOUR_DATES: "Select your dates",
    CHAT_WITH_HOST: "Chat with host",
    EMAIL_HOST: "Email host",
    NEW: "New",
    ADMIN: "Admin",
    ADD_DATES: "Add dates",
    PLAN_TRIPS_GO_EXPERIENCE: "Plan Trips. Go Experience.",
    HUNDREDS_OF_OUTFITTERS:
        "Hundreds of outfitters and charters across North America have joined Mallard Bay",
    BOOK_NOW: "Book Now",
    BOOKED: "Booked",
    LOG_IN: "Log in",
    ACCOUNT: "Account",
    TRIPS: "Trips",
    TRIP_LOWER: "trip",
    INBOX: "Inbox",
    WISHLISTS: "Wishlists",
    HELP: "Help",
    LIGHT_MODE: "Light mode",
    ADMIN_DASHBOARD: "Admin dashboard",
    MANAGE_LISTINGS: "Manage listings",
    LOG_OUT: "Log out",
    SIGN_UP_OR_LOG_IN: "Sign up or log in",
    START_YOUR_ADVENTURE: "Start your adventure",
    OTHER_ADVENTURES_IN: "Other adventures in",
    MALLARD_BAY_LOGO: "Mallard Bay Logo",
    USER: "User",
    FOOTER_COPY:
        "The quickest way for a sportsman to book with reputable guides and outfitters.",
    COPYRIGHT_CHAR: "©",
    MALLARD_BAY_INC: "Mallard Bay, Inc.",
    MADE_WITH: "Made with",
    IN_BATON_ROUGE: "in Baton Rouge, LA",
    ARTICLES: "Articles",
    RECENT_ARTICLES: "Recent Articles",
    ABOUT_US: "About Us",
    MALLARD_BAY_NEWS: "Mallard Bay News",
    HELP_AND_SUPPORT: "Help & Support",
    SHOP_GEAR: "Shop Gear",
    LIST_ON_MALLARD_BAY: "List on Mallard Bay",
    DEMO_GUIDETECH: "Demo Guidetech",
    PRIVACY: "Privacy",
    TERMS: "Terms",
    SITEMAP: "Sitemap",
    PAYMENT_INFO: "Payment info",
    YOUR_TOTAL: "Your total",
    DISCOUNT: "Discount",
    YOU_SAVED: "You saved ",
    DETAILS: "Details",
    PAYMENT_SCHEDULE: "Payment schedule",
    ITINERARY: "Itinerary",
    DOWNLOAD_RECEIPT: "Download receipt",
    ADDITIONAL_PAYMENTS_AND_TIPS: "Additional payments & tips",
    NONE: "None",
    DUE: "due",
    PAY: "Pay",
    VIEW: "View",
    PAID_IN_PERSON: "Paid in person",
    DUE_ON: "Due on",
    DUE_NOW: "Due now",
    NOW: "now",
    DUE_AFTER_CONFIRMATION: "Due after booking confirmation",
    TIP: "Tip",
    PAYMENT_SUCCESSFUL: "Payment successful!",
    PAYMENT_FAILED: "Payment failed.",
    ERROR: "Error",
    UPDATE: "Update",
    CONTINUE: "Continue",
    PAYMENT_REQUEST_NOT_FOUND: "Payment request not found.",
    PAYMENT_REQUEST_SUCCESS: "Payment request has been successfully completed!",
    NO_FURTHER_ACTION_REQUIRED: "No further action is required.",
    ADDITIONAL_EXPENSES: "Additional Expenses",
    SUBMIT_PAYMENT: "Submit Payment",
    OUTFITTERS: "Outfitters",
    OUTFITTERS_START_HERE: "Outfitters start here",
    BECOME_AN_OUTFITTER: "Become an Outfitter",
    RECENTLY_BOOKED_TRIPS: "Recently booked trips",
    NOTIFICATIONS: "Notifications",
    SEO: {
        GROUP_PAY_MEMBER_TITLE: "Your invite to join a group on Mallard Bay",
        GROUP_PAY_MEMBER_DESCRIPTION:
            "You have been invited to a trip on Mallard Bay. Join your group to pay your share and secure your spot.",
        HELP_TITLE: "Help - Contact Customer Support",
        HELP_DESCRIPTION:
            "Contact Mallard Bay if you have any questions or concerns about Booking or Hosting Guided Hunting & Fishing Trips!",
    },
    SHARE: {
        SHARE: "Share",
        NATIVE_SHARE_ERROR: "Native share is not supported on this device",
        SHARE_THIS_OUTFITTER: "Share this outfitter",
        SHARE_THIS_ADVENTURE: "Share this adventure",
        FACEBOOK: "Facebook",
        TWITTER: "Twitter",
        EMAIL: "Email",
        COPY_LINK: "Copy Link",
        LINK_COPIED: "Link Copied",
    },
    HOW_MANY_GUESTS: "How many guests would you like to pay for?",
    REMAINING_SLOTS: "Remaining slots",
    HOW_IT_WORKS: "How it works",
    GROUP_PAY_NOTE_ONE: "You will be required to pay for at least one slot",
    GROUP_PAY_NOTE_TWO:
        "The remaining slots can be claimed and paid for by new members joining your group",
    GROUP_PAY_NOTE_THREE:
        "A group pay joining link will be provided after the booking request is made",
    GROUP_PAY_NOTE_FOUR:
        "If there are slots unclaimed, you will be charged for the remaining amount before the trip starts, on the last payment due date",
    GROUP_PAY_TITLE: "Would you like to use group payment?",
    GROUP_PAY_SUBTITLE:
        "Group payment allows you to split the total cost of the booking with other guests",
    NO: "No",
    YES: "Yes",
    STARTS: "starts",
    ENDS: "ends",
    PAY_ENTIRE_COST: "Pay for the entire cost of the booking",
    INVITE_OTHERS_TO_SHARE_COST:
        "Invite others to share the cost of the booking",
    BOOKING_MEMBERSHIP_ERROR: "You are not a member of this trip.",
    ACCOUNT_NAV: {
        PROFILE: "Profile",
        PAYMENT_METHODS: "Payment methods",
        PAYMENT_HISTORY: "Payment history",
        CHANGE_PASSWORD: "Change password",
        HELP_AND_SUPPORT: "Help & support",
    },
    ACH_BANK_TRANSFER: "ACH Bank Transfer",
    CHECKING: "Checking",
    SAVINGS: "Savings",
    BUSINESS_CHECKING: "Business Checking",
    ADD_BANK_ACCOUNT: "Add bank account",
    PAY_WITH_ACH: "Pay with ACH",
    ADD_CREDIT_CARD: "Add credit card",
    ADD_PAYMENT_METHOD: "Add payment method",
    ADD_BANK_ACCOUNT_INFO: "Add Bank Account Info",
    NAME_ON_ACCOUNT: "Name on Account",
    ACCOUNT_NUMBER: "Account Number",
    ROUTING_NUMBER: "Routing Number",
    ACCOUNT_TYPE: "Account Type",
    ACH_NOT_AVAILABLE_MESSAGE:
        'ACH is only available for "Pay in full" payment plans.',
    GROUP_PAY: {
        INVITE_MEMBER_LINK_LABEL: "Invite new members via the following link",
        INVITE_MEMBER_LINK_VALID_UNTIL: "Valid until",
        GROUP_PROGRESS_COMPLETE: "group complete",
        GROUP_PROGRESS_INCOMPLETE: "group incomplete",
        INVITE_FORM_CONTACT_HEADING: "Contact information",
        INVITE_FORM_PAYMENT_AMOUNT_HEADING: "Payment amount",
        INVITE_FORM_PAYMENT_METHOD_HEADING: "Payment method",
        ACCEPT_INVITE_BUTTON: "Pay & Accept Invite",
        INVITE_MEMBER_PAGE_TITLE: "Your trip invite from",
        INVITE_MEMBER_PAGE_TITLE_ALL_SET: "You're all set!",
        INVITE_MEMBER_PAGE_DESCRIPTION:
            "To finish up the booking process, please provide your contact & payment information.",
        INVITE_MEMBER_PAGE_DESCRIPTION_HOST_INVITE: "has invited you to a",
        INVITE_MEMBER_PAGE_DESCRIPTION_ACCEPTED: "Get ready for",
        HOST_LABEL: "Host (you)",
    },
    FORM_LABELS: {
        NAME: "Name",
        EMAIL: "Email",
        DECREMENT: "Decrement",
        INCREMENT: "Increment",
    },
    CHECKOUT_PAYMENT_NOTE:
        "You will not be charged until the trip is approved by the outfitter. Bank transfers will take an additional 1-2 days to process.",
    CHECKOUT_ACH_PAYMENT_NOTE:
        "When using an ACH payment method, you will be charged in full upon booking the trip. It may take up to 2 days to process your payment.",
    ADD_TO_APPLE_CALENDAR: "Add to Apple Calendar",
    ADD_TO_GOOGLE_CALENDAR: "Add to Google Calendar",
    MALLARD_BAY_TRIP: "Mallard Bay Trip",
    LISTING_CONTACT_BUTTON_LABEL_ONE: "Need help or have questions?",
    LISTING_CONTACT_BUTTON_LABEL_TWO: "Click here or call us at (225) 725-7543",
    CONTACT_MODAL_MESSAGE:
        "We'll get in touch as soon as possible to answer any questions you may have.",
    CONTACT_ME: "Contact Me",
    CONTACT_US: "Contact Us!",
    REQUEST_TO_BE_CONTACTED: "Request to be contacted",
    EMAIL: "Email",
    PHONE: "Phone",
    SAVE: "Save",
    CLOSE: "Close",
    CANCEL: "Cancel",
    CONTINUE_TO_CHECKOUT: "Continue to checkout",
    CONTACT_REQUEST_SENT: "You're request to be contacted has been sent!",
    BOOK_YOUR_TRIP_WITH: "Book your trip with",
    BACK: "Back",
    LISTING: "Listing",
    DATES: "Dates",
    PACKAGE: "Package",
    GUESTS: "Guests",
    ADD_ONS: "Add-ons",
    SUMMARY: "Summary",
    CHECK_IN: "Check-in",
    CHECK_OUT: "Check-out",
    BOOK_WITH: "Book with",
    UNABLE_TO_FIND_QUOTE: "Unable to find quote.",
    PACKAGES_SUMMARY: "Packages Summary",
    PACKAGE_SUMMARY: "Package Summary",
    PACKAGE_SUMMARY_NOTE:
        "This is a summary of the current package being added. You will have a chance to review a summary of the entire trip before checkout.",
    YOUR_PARTY_SIZE: "Your Party Size",
    ADD_ONS_INCLUDED: "Add-ons included in this package",
    YOUR_GUESTS: "Your guests",
    ADDITIONAL_ADD_ONS_TITLE: "Select any additional add-ons",
    ADD_ONS_TITLE: "Select your add-ons",
    SELECT: "Select",
    PRICING: {
        PER_DAY: "/ day",
        PER_GUEST: "/ guest",
        DEPOSIT: "Deposit",
        TOTAL: "Total",
        DUE_AFTER_TRIP: "Due after trip",
    },
    ADD_TO_CART: "Add to Cart",
    ADD_ANOTHER_PACKAGE: "Add another package",
    ADDITIONAL_ADD_ONS_SELECTED: "Additional add-ons selected",
    GUEST: "guest",
    UP_TO: "Up to",
    DELETE: "Delete",
    EDIT: "Edit",
    BOOK_A: "Book a",
    TRIP_IN: "trip in",
    BOOK: "Book",
    FOR_YOUR_NEXT_ADVENTURE: "for your next outdoors adventures on Mallard Bay",
    LODGING: "Lodging",
    TROPHIES: "Trophies",
    MEALS: "Meals",
    GEAR_RENTALS: "Gear Rentals",
    OTHER_ADD_ONS: "Other Add-ons",
    SELECTED_ADD_ONS: "Selected add-ons",
    UPDATE_CART: "Update Cart",
    APPLY: "Apply",
    REMOVE: "Remove",
    MISSING_BOOKING_INVITE_INFO: `This package needs some selections from you. Please click "Update" to complete your selections.`,
    BOOKING_INVITE_REVIEW: "Booking Invite Review",
    EDIT_DATES: "Edit dates",
    NO_DATES_SELECTED: `No dates selected. Please click "Edit dates" to choose dates for your trip.`,
    UPDATE_PACKAGE_INFO: "Update Package Info",
    GUIDE: "Guide",
    OUR_GUIDE: "Our guide",
    LODGINGS: {
        LODGING_BED: "Lodging bed",
        OUTFITTER_NO_LODGINGS:
            "This outfitter currently has no lodging available.",
        NO_DESCRIPTION: "No description available",
        NO_LODGING_ALT_TEXT: "No lodgings",
        CHECKOUT_INCLUDED_LODGING:
            "Your selected package includeds the following lodge option",
    },
    POST_TRIP: "Post-trip",
    // eslint-disable-next-line id-length
    X: "x",
    DAY: "day",
    NO_INCLUDED_ADD_ONS: "This package doesn't include any add ons.",
    REQUEST_TO_BOOK: "Request to book",
    VIEWING: "Viewing",
    LICENSED_OUTFITTER: "Licensed outfitter",
    EXPERIENCE: "Experience",
    INFO: "Info",
    PAYMENT: "Payment",
    CONFIRMATION: "Confirmation",
    SHOW_MORE: "Show more",
    SHOW_LESS: "Show less",
    RULES: "Rules",
    TARGET_SPECIES: "Target species",
    HUNTING: "Hunting",
    FISHING: "Fishing",
    AMENITIES: "Amenities",
    TRIP_INCLUDES: "Trip includes",
    TRIP_REQUIRES_BOTH: "This trip requires both a ",
    HUNTING_LICENSE: "hunting license",
    FISHING_LICENSE: "fishing license",
    AND_A: " and a ",
    TRIP_REQUIRES: "This trip requires a ",
    LICENSE: "license",
    LICENSE_REQUIRED: "License Required",
    OBTAIN_LICENSE: "Obtain License",
    FROM: "from",
    PERSONAL_INFORMATION: "Personal Information",
    PAYMENT_PLAN: "Payment Plan",
    FIRST_NAME: "First Name",
    LAST_NAME: "Last Name",
    YOU_CAN_VIEW_YOUR_TRIPS:
        "You can view your trips at the link below by logging in to your",
    MALLARD_BAY_WITH_SPACES: " Mallard Bay ",
    SIGN_UP_WITH: "Sign up with",
    TO_VIEW_YOUR_TRIPS: "to view your trips or book another!",
    SUCCESS: "Success!",
    VIEW_YOUR_TRIP: "View your trip",
    PASSWORD_NOTE: "Password must contain at least 8 characters.",
    SIGN_UP: "Sign up",
    SIGN_UP_SUCCESS: "Success! Your account has been created.",
    PASSWORD: "Password",
    SHOW_OUTFITTER: "Show outfitter",
    CALL_HOST: "Call host",
    YOUR_HOST: "Your host",
    OWNER: "Owner",
    OUTFITTER: "Outfitter",
    SHOW_LISTING: "Show listing",
    GROUP_PACKAGES: "Group Packages",
    INDIVIDUAL_PACKAGES: "Individual Packages",
    PRICING_OPTIONS: "Pricing Options",
    REPORT_THIS_LISTING: "Report this listing",
    CONTACT_OUTFITTER: "Contact Outfitter",
    APPLY_A_COUPON: "Apply a coupon",
    ENTER_COUPON_CODE: "Enter coupon code",
    POWERED_BY: "Powered by",
    GUIDE_TECH: "GuideTech",
    WIDGET: {
        WHICH_LISTING: "Which listing will be",
        YOUR: "your",
        NEXT_ADVENTURE: "next adventure?",
        CHOOSE_LISTING: "Choose the listing you'd like to experience",
    },
    NO_MATCHING_SUGGESTIONS: "No matching suggestions",
    LISTINGS: "Listings",
    OUTFITTERS_IN: "Outfitters in",
    HUNT_AND_FISH: "Hunt & Fish with Local Guides",
    REGION_SEO_DESCRIPTION_PREFIX:
        "Browse, plan, contact, and transact directly with local Hunting Guides in",
    REGION_SEO_DESCRIPTION_SUFFIX:
        "The Fastest and Easiest way to Directly Book Hunts.",
    PHONE_LABEL: "Phone number*",
    PHONE_CAPTION: "We will only notify you of your bookings",
    SUBMIT: "Submit",
    WHERE_DID_YOU_HEAR_ABOUT_US: "Where did you hear about us?",
    THANK_YOU_FOR_YOUR_FEEDBACK: "Thank you for your feedback!",
    REQUIRED: "Required",
    TOTAL_PAID: "Total paid",
    AMOUNT_DUE: "Amount due",
    SUBTOTAL: "Subtotal",
    TAXES: "Taxes",
    PROCESSING_FEE: "Processing Fee",
    AMOUNT_REMAINING_AFTER_DEPOSIT: "Amount remaining after deposit",
    VIEW_INVOICE: "View invoice",
    REFUNDS_ISSUED: "Refunds Issued",
    BY: "By",
    BY_LOWER: "by",
    ON: "On",
    STATUS: "Status",
    APPROVED_BY: "Approved by",
    CANCELLED_BY: "Cancelled by",
    AT: "At",
    AMOUNT: "Amount",
    FROM_CAPITALIZED: "From",
    POPULAR_AMENITIES: "Popular amenities",
    YOUR_GUIDE_TO: "Your guide to",
    IN: "in",
    FEATURED_LISTINGS: "Featured listings",
    FEATURED_OUTFITTERS: "Featured outfitters",
    OTHER_LISTINGS: "Other listings",
    OTHER_OUTFITTERS: "Other outfitters",
    EXPLORE_OTHERS: "Explore others",
    PAY_WITH_CHECK: "Pay With Check",
    MAKE_PAYABLE_TO: "Make payable to:",
    MAIL_TO: "Mail to:",
    MALLARD_PAY_OUTDOORS: "Mallard Bay Outdoors",
    MB_ADDRESS_LINE_ONE: "1100 West 23rd St Suite 222",
    MB_ADDRESS_LINE_TWO: "Houston Tx, 77008",
    CONFIRM: "Confirm",
    CHANGE_PASSWORD_SUCCESS: "Password updated successfully",
    PASSWORDS_DO_NOT_MATCH: "Passwords do not match",
    UPLOAD_AVATAR_SUCCESS: "Avatar updated successfully",
    INVALID_FILE_ERROR: "Invalid file type or file size too large",
    CHOOSE_A_STATE: "Choose a state",
    CHOOSE_A_SPECIES: "Choose a species",
    REMOVED_FROM: "Removed from",
    ADDED_TO: "Added to",
    VIEW_LIST: "View list",
    PHONE_PLACEHOLDER: "(999) 555 1234",
    PAGE_NOT_FOUND_ERRORS: {
        LISTING_NOT_READY:
            "The listing you are trying to access is not ready yet",
        OUTFITTER_IS: "The outfitter you are trying to access is",
        PAGE_DOES_NOT_EXIST: "The page you are trying to access does not exist",
        TRY_FEATURED_LISTING:
            "Try visiting one of the featured listings below instead",
        OUTFITTER_NOT_APPROVED:
            "Once the outfitter is approved, you will be able to see it here",
        CAN_NOT_BOOK_TRIPS: "You cannot currently book trips with them",
    },
    PENDING_APPROVAL: "pending approval",
    CURRENTLY_SUSPENDED: "currently suspended",
    THIS_LISTING_DOES_NOT_HAVE_ANY_AVAILABILITY_CURRENTLY:
        "This listing does not have any availability currently",
    LISTING_IMAGE: "Listing image",
    LISTED: "listed",
    OUTFITTER_IMAGE: "Outfitter image",
    SEO_INDEX: {
        TITLE: "Mallard Bay Outdoors - Guided Trips",
        DESCRIPTION:
            "Find the best hunting guides & fishing charters. Search and book directly online for hundreds of guided trips across North America. Explore Today!",
        KEYWORDS: SEO_KEYWORDS,
    },
    FOR_MORE_ADD_ON_INFO_PREFIX:
        "For more information and examples, please see",
    FOR_MORE_ADD_ON_INFO_LINK_TEXT: "this article",
    PERIOD: ".",
    SHOW_ALL_PHOTOS: "Show all photos",
    VIDEO: "Video",
    PHOTO: "Photo",
    ABOUT: "About",
    OUTFITTER_HAS_NO_LISTINGS: "This outfitter hasn't published any listings",
    REVIEWS: "Reviews",
    SEE_ALL_REVIEWS: "See all reviews",
    ALL_REVIEWS: "All reviews",
    EXPLORE_OTHER_OUTFITTERS_IN: "Explore other outfitters in",
    EDIT_THIS_LISTING: "Edit this listing",
    LICENSE_OPTIONAL: "License optional",
    ADVENTURE_GUIDED_BY: "adventure guided by",
    MINIMUM: "minimum",
    GUIDED_BY: "Guided by",
    JOINED_IN: "Joined in",
    LOCATION: "Location",
    VISIT_OUR_KNOWLEDGE_BASE: "Visit our knowledge base",
    EMAIL_US_AT: "Email us at",
    CALL_US_AT: "Call us at",
    HELP_PAGE_TITLE: "Stuck? We're here to help!",
    HELP_PAGE_BODY:
        "Mallard Bay is committed to providing the best service for each and every experience. We are obsessed with connecting the dots between the online and outdoor communities.",
    EXACT_LOCATION_PROVIDED_AFTER_BOOKING:
        "Exact location provided after booking.",
    PROCESSING_FEE_WARNING:
        "* Total is subject to a processing fee at checkout based on payment method",
    CANCELLATION_POLICY: "Cancellation policy",
    CANCELLATION_POLICY_INFO: "Cancellation Policy (i)",
    NEWSFEED: "NewsFeed",
    DISCOUNT_LABEL: "Discount: ",
    DISCOUNT_WARNING:
        "* Discount can only be applied up to the subtotal of your booking.",
    PAYMENT_FAILED_DESCRIPTION:
        "We were unable to process your recent payment due to an issue with your card. To avoid any interruptions, please update your card details as soon as possible.",
    UPDATE_PAYMENT_METHOD: "Update payment method",
    RETRY_PAYMENT_SUCCESS: "Payment retried successfully!",
    STRIPE_NOT_INITIALIZED_ERROR: "Failed to initialize stripe",
    PAYMENT_METHOD_REQUIRED: "Payment method is required",
    RETRY_PAYMENT: "Retry payment",
    EXISTING_PAYMENT_METHOD: "Existing payment method",
    ADD_NEW: "Add new",
    DELETE_PAYMENT_METHOD_SUCCESS: "Successfully deleted payment method!",
    UPDATE_PAYMENT_METHOD_SUCCESS: "Successfully updated payment method!",
    CHANGE_PAYMENT_METHOD: "Change payment method",
    WIDGET_WITHOUT_ID_ERROR: "Widget loaded with no widgetId",
    BOOK_YOUR_TRIP: "Book your trip",
    OFF: "off",
    DEFAULT: "Default",
    DELETE_PAYMENT_METHOD_PROMPT:
        "Are you sure you would like to delete this payment method?",
    NEVERMIND: "Nevermind",
    WARNING: "Warning",
    SUSPENDED_OUTFITTER_TEXT:
        "Your booking can't be processed until the suspension is lifted.",
    PLEASE_CONTACT: "Please contact",
    TO_VERIFY_BOOKING: "to verify this booking is still valid.",
    IS_SUSPENDED: "is suspended",
    CONTACT_SUPPORT: "Contact support",
    HOW_DO_YOU_WANT_TO_PAY: "How do you want to pay for this trip?",
    SELECT_PAYMENT_METHOD: "Select payment method",
    PAYMENT_PLAN_INFO_SECTION_A_LINE_1:
        "You can pay for some of this payment now, and the rest later.",
    PAYMENT_PLAN_INFO_SECTION_A_LINE_2: "With no extra cost or fees.",
    PAYMENT_PLAN_INFO_SECTION_B_LINE_1:
        "You'll pay the rest at the denoted times automatically.",
    PAYMENT_PLAN_INFO_SECTION_B_LINE_2: "No need for approvals.",
    PAYMENT_PLAN_INFO_SECTION_C_LINE_1:
        "You will be notified before we proceed with the payment.",
    PAYMENT_PLAN_INFO_SECTION_C_LINE_2: "So you won't have to worry.",
    LEARN_MORE: "Learn more",
    TBD: "TBD",
    TOAST_TITLES: { SUCCESS: "Success!", ERROR: "Error!" },
    NO_TRIPS_FOUND:
        "It doesn't look like you've had any bookings. They'll be here when you do!",
    EXPLORE_POPULAR_STATES: "Explore popular states",
    SELECT_ANOTHER_LISTING: "Select another listing",
    CARDS_ON_FILE: "Cards on file",
    BANKS_ON_FILE: "Banks on file",
    BANK_ACCOUNT: "Bank account",
    SET_AS_DEFAULT: "Set as default",
    NO_PAYMENT_METHODS_SAVED: "You have no payment methods saved.",
    INCREMENT: "increment",
    DECREMENT: "decrement",
    NEXT_MONTH: "Next month",
    PREVIOUS_MONTH: "Previous month",
    MENU: "Menu",
    PREVIOUS: "Previous",
    NEXT: "Next",
    SEARCH_PLACEHOLDER: "Search...",
    CLEAR_SEARCH: "Clear search",
    APPLY_FILTERS: "Apply filters",
    CLEAR_ALL: "Clear all",
    FILTERS: "Filters",
    GUESTS_AND_ADD_ONS: "Guests & Add-ons",
    ADDRESS: "Address",
    COPY: "Copy",
    COPIED: "Copied",
    RECENT_SEARCHES: "Recent searches",
    GET_DIRECTIONS: "Get directions",
    SEARCHING: "Searching",
    NO_MATCHES: "There are no exact matches",
    TRY_FILTERS: "Try adjusting your filters to explore more listings.",
    EXPLORE_DESTINATIONS: "Explore destinations",
    PAID: "Paid",
    IN_PERSON: "In person",
    HIDE: "Hide",
    SHOW: "Show",
    CAPITALIZED_DUE: "Due",
    PAY_NOW: "Pay now",
    BASIC: "Basic",
    INCLUDES: "Includes",
    FEATURES: "Features",
    EXPLORE_HUNTING: "Explore hunting outfitters",
    EXPLORE_FISHING: "Explore fishing charters",
    LISTING_TYPE: "Listing type",
    PRICE_RANGE: "Price range",
    SET_MIN_MAX_PRICE: "Set a minimum and maximum price",
    SAVE_AND_CONTINUE_EXPLORING: "Save and continue exploring",
    UPLOAD: "Upload",
    NO_PAYMENT_METHODS: "You have not added any payment methods yet.",
    PAYMENT_METHODS: "Payment Methods",
} as const
