import React from "react"
import { PillButton } from "@mallardbay/lib-react-components"

import { useAuth } from "~utils/auth-hooks"
import COPY from "~config/copy-constants"

export default function HeaderLoginButton() {
    const { loginWithRedirect } = useAuth()

    return (
        <PillButton variant="outline" onClick={loginWithRedirect}>
            {COPY.SIGN_UP_OR_LOG_IN}
        </PillButton>
    )
}
