import React from "react"
import { HorizontalStack } from "@mallardbay/lib-react-components"

import Skeleton from "~components/shared/todo-lib-react-components/skeleton"
import { SUGGESTION_IMG_HEIGHT } from "~config/constants"

export default function OptionSkeletonRow() {
    const width = SUGGESTION_IMG_HEIGHT

    return (
        <HorizontalStack>
            <Skeleton width={width} height={width} />
            <Skeleton width="200px" height="30px" />
        </HorizontalStack>
    )
}
