import React from "react"
import { useLocation } from "@reach/router"
import type { StyleProps } from "@mallardbay/lib-react-components"
import { componentStyles } from "@mallardbay/lib-react-components"

import type { TestId } from "~config/test-ids"
import { MAX_WIDTH } from "~config/constants"
import Box from "~components/shared/todo-lib-react-components/box"
import type { PropsWithChildrenAndStyle } from "~components/shared/todo-lib-react-components/types"

interface Props extends PropsWithChildrenAndStyle {
    readonly testId?: TestId
    readonly noPadding?: boolean
    readonly style?: StyleProps
}

export default function ResponsiveBlock({
    style,
    children,
    testId,
    noPadding,
}: Props) {
    const { pathname } = useLocation()
    const isNarrow = ["/search", "/wishlists/", "/inbox"].some((path) =>
        pathname.includes(path)
    )
    const styles = useStyles({ noPadding, isNarrow })

    return (
        <Box style={{ ...styles.root, ...style }}>
            <Box testId={testId} style={styles.content}>
                {children}
            </Box>
        </Box>
    )
}

function useStyles({
    noPadding,
    isNarrow,
}: {
    noPadding?: boolean
    isNarrow: boolean
}) {
    const paddingX = noPadding ? 0 : 6
    const maxWidth = isNarrow ? "auto" : MAX_WIDTH

    return componentStyles({
        root: {
            paddingX,
        },
        content: {
            maxWidth,
            width: "100%",
            marginTop: "0",
            marginRight: "auto",
            marginBottom: "0",
            marginLeft: "auto",
            transition: "all 0.2s ease-in-out",
        },
    })
}
